import React, { useState} from 'react';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {useParams, useNavigate} from 'react-router-dom';
import {FormikProvider, Form, useFormik} from 'formik';
// mui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Switch,
  Stack,
  TextField,
  Typography,
  CircularProgress
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTheme} from '@mui/styles';
import DatePicker from '@mui/lab/DatePicker';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {updateAsset} from 'src/redux/slices/asset';
import {deleteFile} from 'src/redux/slices/file';
// utils
import {getNickName} from 'src/utils/formatNickname';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import NumberFormat from 'src/components/NumberFormat';
import {
  AddressFields,
  ContactSelect,
  FormikErrorNotification,
  TextFieldWithHover
} from 'src/components/_dashboard/common';
import VinField from './VinField';
import MakeFields from './MakeFields';
// constants
import {US_STATES} from 'src/constants/states';
import {AU_MODEL} from 'src/constants/cars';
import {AU_TYPE, ASSET_STATUS} from 'src/constants/dropdown';
import {COUNTRY_LIST} from 'src/constants/countries';
import {fDateZeroTime} from 'src/utils/formatTime';

// ----------------------------------------------------------------------


AssetEditPage.propTypes = {
  data: PropTypes.object.isRequired,
  inLoading: PropTypes.bool,
  policies: PropTypes.array.isRequired
};

export default function AssetEditPage({data, inLoading = false, policies = []}) {
  const {user} = useAuth();
  const theme = useTheme();
  const isAdvisor = user && user.role === 'Advisor';

  const {id, userId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const [toBeDeleted, setToBeDeleted] = useState([]);
  const EditSchema = Yup.object().shape({
    imageFiles: Yup.array(),
    idNumber: Yup.string().required('ID number is required.'),
    amModel: Yup.string().required('Please provide Model'),
    make: Yup.string().required('Please provide Make'),
    year: Yup.number().required('Please provide year'),
    leasedVehicleDateExecuted: Yup.date().nullable().min(new Date(1900, 0, 1)),
    leasedVehicleDateExpired: Yup.date().nullable().min(new Date(1900, 0, 1)),
    regExpDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    purDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    soldDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    lastMADate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    lRADate: Yup.date().nullable().min(new Date(1900, 0, 1))
  });

  const formik = useFormik({
    initialValues: {
      vehicleType: data.vehicleType || '',
      customVehicleType: data.customVehicleType || '',
      year: data.year || '',
      nickName: data.nickName || '',
      make: data.make || '',
      customMake: data.customMake || '',
      amModel: data.amModel || '',
      idNumber: data.idNumber || '',
      // Garaging Address
      garagingAddressId: data.garagingAddressId || '',

      // lease vehicle with dates
      isLeasedVehicle: data.isLeasedVehicle || false,
      leasedVehicleDateExecuted: data.leasedVehicleDateExecuted,
      leasedVehicleDateExpired: data.leasedVehicleDateExpired,

      // Registration
      isRegistered: data.isRegistered || false,
      regState: data.regState || '', // State Registered
      regCountry: data.regCountry || '',
      regNumber: data.regNumber || '', // Registration Number
      regExpDate: data.regExpDate, // Registration Expiratioin Date

      owner: data.owner || [], // Owner
      addInterest: data.addInterest || [], // Additional Interest
      purDate: data.purDate, // Purchase Date
      soldDate: data.soldDate, // Sold Date
      marketValue: data.marketValue || '',
      lastMADate: data.lastMADate, // Last Market Appraisal Date
      lastMAValue: data.lastMAValue || '', // Last Market Appraisal Value
      rValue: data.rValue || '', // Replacement Value
      lRADate: data.lRADate, // Last Replacement Appraisal Date
      lRAValue: data.lRAValue || '', // Last Replacement Appraisal Value
      assetStatus: data.assetStatus || 'active',
      insured: data.insured || false,
      insuredValue: data.insuredValue || '',
      rPolicies: data.rPolicies || [], // Related Policies
      note: data.note || '',

      rValueDocs: data.rValueDocs || [], // Replacement Value Documents
      purDocs: data.purDocs || [], // Purchase Documents
      valDocs: data.valDocs || [], // Valuation Documents
      mRecords: data.mRecords || [], // Maintenance Records
      regDocs: data.regDocs || [], // Registration Documents
      imageFiles: data.imageFiles || []
    },
    validationSchema: EditSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        handleDeletables();
        const payload = {...data, nickName: values.nickName || getNickName(values, 'automobile'), ...values};
        const res = await dispatch(updateAsset(payload, userId, !isAdvisor));
        setSubmitting(false);
        if (res && res?.result) {
          enqueueSnackbar('Asset Updated Successfully.', {variant: 'success'});

          if (!!userId) {
            const route = `${PATH_DASHBOARD.root}/asset/automobile/${id}/${userId}`;
            navigate(route, {state: {view: data.type}});
          } else {
            const route = `${PATH_DASHBOARD.root}/asset/automobile/${id}`;
            navigate(route, {state: {view: data.type}});
          }
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor')
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          else enqueueSnackbar('Asset update error.', {variant: 'error'});
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, getFieldProps, setFieldValue, handleSubmit, isSubmitting} = formik;

  

  const handleDeletables = () => {
    toBeDeleted.forEach((id) => dispatch(deleteFile(id)));
    setToBeDeleted([]);
  };

  let models = AU_MODEL.filter((item) => item.make === values.make);
  const firstModel = models.find((item) => item.order === 'first');
  const lastModel = models.find((item) => item.order === 'last');
  if (firstModel && lastModel) {
    for (let i = parseInt(firstModel.model, 10) + 1; i < parseInt(lastModel.model, 10); i++) {
      models.push({...firstModel, model: `${i}`});
    }
  }

  models = models.sort((a, b) => {
    if (Number.isNaN(parseInt(b.model, 10)) && !Number.isNaN(parseInt(a.model, 10))) return 1;
    return a.model > b.model ? 1 : -1;
  });

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Stack spacing={2}>
          {/* Top section */}
          <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
            <TextField
              select
              fullWidth
              label="Type"
              {...getFieldProps('vehicleType')}
              onChange={(e) => setFieldValue('vehicleType', e.target.value)}
            >
              <MenuItem value="custom">Custom</MenuItem>
              {AU_TYPE.sort((a, b) => (a.label > b.label ? 1 : -1)).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {data.vehicleType === 'custom' && (
              <TextField
                fullWidth
                label="Custom Vehicle Type"
                {...getFieldProps('customVehicleType')}
                onChange={(e) => setFieldValue('customVehicleType', e.target.value)}
                error={Boolean(touched.customVehicleType && errors.customVehicleType)}
                helperText={touched.customVehicleType && errors.customVehicleType}
              />
            )}
            <TextField
              {...getFieldProps('year')}
              fullWidth
              type="number"
              label="Year"
              error={Boolean(touched.year && errors.year)}
              helperText={touched.year && errors.year}
            />
          </Stack>
          <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
            <Stack style={{flex: 1}}>
              <TextFieldWithHover
                {...getFieldProps('nickName')}
                fullWidth
                type="string"
                label="Asset Name"
                info="Input the asset name"
              />
            </Stack>

            {/* new vin field */}
            <Stack style={{flex: 1}}>
              <VinField
                name={'VIN'}
                setVin={(idNumber) => setFieldValue('idNumber', idNumber)}
                vin={values.idNumber}
                setVinData={(vinData) => {
                  const vinMake = vinData?.Make;
                  const vinModel = vinData?.Model;
                  const vinMarketValue = vinData?.BasePrice;
                  const vinYear = vinData?.ModelYear;

                  !!vinMake && setFieldValue('make', vinMake);
                  !!vinModel && setFieldValue('amModel', vinModel);
                  !!vinMarketValue && setFieldValue('marketValue', vinMarketValue);
                  !!vinYear && setFieldValue('year', vinYear);
                }}
              />
            </Stack>
          </Stack>

          {/* new make/model fields */}
          <MakeFields
            make={values?.make}
            setMake={(make) => setFieldValue('make', make)}
            model={values?.amModel}
            setModel={(model) => setFieldValue('amModel', model)}
          />

          <Typography>Garaging Address</Typography>
          <AddressFields
            isRealEstate={false}
            addId={values.garagingAddressId}
            handleChange={(id) => setFieldValue('garagingAddressId', id)}
          />
          <Box sx={{width: '100%'}}>
            <FormControlLabel
              label="Leased Vehicle"
              sx={{ml: 0}}
              control={
                <Checkbox
                  checked={values.isLeasedVehicle}
                  onChange={(e) => setFieldValue('isLeasedVehicle', e.target.checked)}
                />
              }
            />
          </Box>
          {values.isLeasedVehicle && (
            <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
              <DatePicker
                fullWidth
                label="Date Executed"
                {...getFieldProps('leasedVehicleDateExecuted')}
                onChange={(date) => setFieldValue('leasedVehicleDateExecuted', fDateZeroTime(date))}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
              <DatePicker
                fullWidth
                label="Date Expired"
                {...getFieldProps('leasedVehicleDateExpired')}
                onChange={(date) => setFieldValue('leasedVehicleDateExpired', fDateZeroTime(date))}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Stack>
          )}
          <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
            <Box sx={{width: '100%'}}>
              <FormControlLabel
                control={<Switch {...getFieldProps('isRegistered')} checked={values.isRegistered} />}
                label="Registered"
                labelPlacement="start"
              />
            </Box>
          </Stack>
          {values.isRegistered && (
            <Stack spacing={1}>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Registration State"
                  {...getFieldProps('regState')}
                  onChange={(e) => setFieldValue('regState', e.target.value)}
                >
                  <MenuItem value="non-us">Non-US</MenuItem>
                  {US_STATES.map((option) => (
                    <MenuItem key={option.code} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                {values.regState === 'non-us' && (
                  <TextField
                    select
                    fullWidth
                    label="Please Specify Country"
                    value={values.regCountry}
                    onChange={(e) => setFieldValue('regCountry', e.target.value)}
                  >
                    {COUNTRY_LIST.map((option) =>
                      option.code !== 'US' ? (
                        <MenuItem key={option.code} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ) : null
                    )}
                  </TextField>
                )}
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField {...getFieldProps('regNumber')} fullWidth type="text" label="Registration Number" />
                <DatePicker
                  fullWidth
                  label="Registration Expiration Date"
                  {...getFieldProps('regExpDate')}
                  onChange={(date) => setFieldValue('regExpDate', fDateZeroTime(date))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Stack>
            </Stack>
          )}
          <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
            <ContactSelect
              clientId={userId}
              keyValue="owner"
              label="Owner"
              values={values.owner}
              setFieldValue={setFieldValue}
            />
            <ContactSelect
              clientId={userId}
              keyValue="addInterest"
              label="Additional Interest"
              values={values.addInterest}
              setFieldValue={setFieldValue}
            />
          </Stack>
          <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
            <DatePicker
              fullWidth
              label="Purchase Date"
              {...getFieldProps('purDate')}
              onChange={(date) => setFieldValue('purDate', fDateZeroTime(date))}
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
            <DatePicker
              fullWidth
              label="Sold Date"
              {...getFieldProps('soldDate')}
              onChange={(date) => setFieldValue('soldDate', fDateZeroTime(date))}
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Stack>

          {/** Footer */}
          <Divider />
          <Stack direction={{xs: 'column', md: 'row'}} spacing={1}>
            <NumberFormat
              fullWidth
              label="Market Value"
              prefix="$"
              placeholder="$0.00"
              {...getFieldProps('marketValue')}
              onChange={(e) => setFieldValue('marketValue', parseFloat(e.target.values.value))}
            />
            <DatePicker
              fullWidth
              label="Last Market Appraisal Date"
              {...getFieldProps('lastMADate')}
              onChange={(date) => setFieldValue('lastMADate', fDateZeroTime(date))}
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
            <NumberFormat
              fullWidth
              label="Last Market Appraisal Value"
              prefix="$"
              placeholder="$0.00"
              {...getFieldProps('lastMAValue')}
              onChange={(e) => setFieldValue('lastMAValue', parseFloat(e.target.values.value))}
            />
          </Stack>
          <Stack direction={{xs: 'column', md: 'row'}} spacing={1}>
            <NumberFormat
              fullWidth
              label="Replacement Value"
              prefix="$"
              placeholder="$0.00"
              {...getFieldProps('rValue')}
              onChange={(e) => setFieldValue('rValue', parseFloat(e.target.values.value))}
            />
            <DatePicker
              fullWidth
              label="Last Replacement Appraisal Date"
              {...getFieldProps('lRADate')}
              onChange={(date) => setFieldValue('lRADate', fDateZeroTime(date))}
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
            <NumberFormat
              fullWidth
              label="Last Replacement Appraisal Value"
              prefix="$"
              placeholder="$0.00"
              {...getFieldProps('lRAValue')}
              onChange={(e) => setFieldValue('lRAValue', parseFloat(e.target.values.value))}
            />
          </Stack>
          <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
            <TextField
              select
              fullWidth
              label="Asset Status"
              {...getFieldProps('assetStatus')}
              onChange={(e) => setFieldValue('assetStatus', e.target.value)}
              helperText="Please select asset status."
            >
              {ASSET_STATUS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
            <Box sx={{width: '100%'}}>
              <FormControlLabel
                control={<Switch {...getFieldProps('insured')} checked={values.insured} />}
                label="Insured"
                labelPlacement="start"
              />
            </Box>
            {values.insured && (
              <NumberFormat
                fullWidth
                label="Insured Value"
                prefix="$"
                placeholder="$0.00"
                {...getFieldProps('insuredValue')}
                onChange={(e) => setFieldValue('insuredValue', parseFloat(e.target.values.value))}
              />
            )}
          </Stack>
          <Autocomplete
            fullWidth
            multiple
            loading={inLoading}
            options={policies}
            ListboxProps={{sx: {maxHeight: '160px'}}}
            disableCloseOnSelect
            getOptionLabel={(option) => `Automobile - ${option.policyNumber} - ${option.nickName}`}
            value={policies.filter((item) => values.rPolicies.some((pol) => pol.id === item.id))}
            onChange={(e, value) =>
              setFieldValue(
                'rPolicies',
                value.map((item) => ({id: item.id, type: 'automobile'}))
              )
            }
            renderOption={(props, option, {selected}) => {
              return (
                <li {...props} key={option.id}>
                  <Checkbox checked={selected} />
                  Automobile - {option.policyNumber} - {option.nickName}
                </li>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Related Policies" />}
          />
          <Stack spacing={2}>
            <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
          </Stack>
          
        </Stack>
        <Box sx={{position: 'fixed', bottom: 20, right: 120, zIndex: 99}}>
          <Button
            onClick={() => navigate(-1)}
            color="inherit"
            variant="contained"
            sx={{mr: 1.5, color: theme?.palette?.text?.primary ?? '#fff'}}
          >
            Cancel
          </Button>
        </Box>
        <Box sx={{position: 'fixed', bottom: 20, right: 40, zIndex: 99}}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            loadingIndicator={<CircularProgress color="inherit" size={16} sx={{color: 'white'}} />}
            onClick={handleSubmit}
            sx={{backgroundColor: '#3366FF !important'}}
          >
            Submit
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
}

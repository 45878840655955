import {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSnackbar} from 'notistack';
// mui
import {Box, Card, CardHeader, CircularProgress, Grid, Stack, Switch, Typography} from '@mui/material';
// api
import {getDashboardData} from 'src/api';
import {getAdvisorDashboardData} from 'src/api/advisor';
// hooks
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
// redux
import {useSelector} from 'src/redux/store';
// utils
import {isNullOrEmpty} from 'src/utils/checkEmpty';
import {LabelWidget} from '../common';
import {filterDCByClient} from 'src/utils/advisor';
// components
import TotalNetWorthChart from './chart-components/TotalNetWorthChart';
import TotalLCoverChart from './chart-components/TotalLCoverChart';
import TotalInsuranceChart from './chart-components/TotalInsuranceChart';
import ComingSoonCover from 'src/components/ComingSoonCover';
import FullScreenChart from '../common/FullScreenChart';
import TotalInsuranceColumnChart from './chart-components/TotalInsuranceColumnChart';
// ----------------------------------------------------------------------

Charts.propTypes = {
  user: PropTypes.object
};

export default function Charts() {
  const {trendShow, onChangeTrend} = useSettings();
  const {enqueueSnackbar} = useSnackbar();
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';
  // NOTE: Advisor flow
  const {list, isLoading} = useSelector((state) => state.client);
  // NOTE: Client flow with Family group data
  const {members} = useSelector((state) => state.familyGroup);
  const smCounts = members.filter((m) => m.show).length; // NOTE: Selected Family Member counts

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const getData = async () => {
      setLoading(true);
      try {
        if (user.role === 'Advisor' || smCounts > 0) {
          const res = await getAdvisorDashboardData(user.role !== 'Advisor');
          if (isMounted) {
            setData(res?.data);
          }
        } else {
          const res = await getDashboardData();
          if (isMounted) {
            setUserData(res?.data);
          }
        }
      } catch (e) {
        if (isMounted) {
          console.warn(e);
          enqueueSnackbar(e?.message || 'Get dashboard info error.', {variant: 'error'});
        }
      } finally {
        setLoading(false);
      }
    };

    getData();

    return () => (isMounted = false);
  }, [enqueueSnackbar, user, smCounts]);

  const advisorData = useMemo(() => {
    const selectedList = list.filter((item) => item.show);
    let familyCount = 0;
    let avClients = 0;
    // NOTE: grouped clients
    const groupedList = {};
    selectedList.forEach((client) => {
      if (client?.familyGroup?.length === 0) {
        const groupName = 'No Family Group';
        const groupId = 'no-family-group';

        if (!groupedList[groupId]) {
          groupedList[groupId] = {groupName, clients: []};
        }
        groupedList[groupId].clients.push(client);
      } else {
        client?.familyGroup?.forEach((group) => {
          const groupName = group.groupName;
          const groupId = group.id;

          if (!groupedList[groupId]) {
            groupedList[groupId] = {groupName, clients: []};
          }
          groupedList[groupId].clients.push(client);
        });
      }
    });

    if (!!Object.keys(groupedList).length) {
      const noFamilyClients = groupedList['no-family-group']?.clients?.length;
      delete groupedList['no-family-group'];

      familyCount = Object.keys(groupedList).length + noFamilyClients;
      const totalClients =
        Object.values(groupedList).reduce((sum, family) => sum + family.clients.length, 0) + noFamilyClients;
      avClients = totalClients / (familyCount || 1);
    }

    return {totalClients: selectedList.length, totalFamilies: familyCount, averageClients: avClients};
  }, [list]);

  const chartData = isAdvisor
    ? filterDCByClient(JSON.parse(JSON.stringify(data)) || [], JSON.parse(JSON.stringify(list)))
    : members.some((m) => m.show)
    ? filterDCByClient(JSON.parse(JSON.stringify(data)) || [], JSON.parse(JSON.stringify(members)))
    : userData || {};


  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{m: 1, height: {xs: 'auto', sm: 'calc(100% - 12px)'}, display: 'flex', flexDirection: 'column'}}>
          {isNullOrEmpty(chartData.netWorth) && !loading && <ComingSoonCover noData={true} style={{}} />}
          <CardHeader title={isAdvisor ? 'Net Worth Under Advisement' : 'Total Net Worth'} />
          <TotalNetWorthChart data={chartData.netWorth} loading={loading} />
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{m: 1, height: {xs: 'auto', sm: 'calc(100% - 12px)'}, display: 'flex', flexDirection: 'column'}}>
          {isNullOrEmpty(chartData.liabilityCoverage) && !loading && <ComingSoonCover noData={true} style={{}} />}
          <CardHeader title={isAdvisor ? 'Total Liability Managed' : 'Total Liability Coverage'} />
          <TotalLCoverChart data={chartData.liabilityCoverage} loading={loading} />
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card
          sx={{
            m: 1,
            height: {xs: 'auto', sm: 'calc(100% - 12px)'},
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            overflow: 'visible'
          }}
        >
          {!loading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 999999,
                py: 1,
                px: 2,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography fontSize={'10px'} color={'text.secondary'}>
                Trend
              </Typography>
              <Switch size="small" checked={trendShow} onChange={(e) => onChangeTrend(e.target.checked)} />
            </Box>
          )}

          {((isNullOrEmpty(chartData.InsurancePremium) && !trendShow) ||
            (isNullOrEmpty(chartData.InsurancePremiumHistory) && trendShow) ||
            (isNullOrEmpty(chartData.AggregatedPropertyValue) && trendShow)) &&
            !loading && <ComingSoonCover noData={true} style={{marginTop: 35}} />}

          <CardHeader
            title={isAdvisor ? 'Total Insurance Premiums Under Advisement' : 'Total Insurance Premiums By Type'}
          />

          {trendShow ? (
            <FullScreenChart
              withTrend
              isEmpty={
                isNullOrEmpty(chartData.InsurancePremiumHistory) || isNullOrEmpty(chartData.AggregatedPropertyValue)
              }
            >
              <TotalInsuranceColumnChart
                data1={chartData.InsurancePremiumHistory}
                data2={chartData.AggregatedPropertyValue}
                label={['Annual Premium', 'Aggregate Property Value']}
                loading={loading}
              />
            </FullScreenChart>
          ) : (
            <TotalInsuranceChart data={chartData.InsurancePremium} loading={loading} />
          )}
        </Card>
      </Grid>

      {isAdvisor && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, height: {xs: 'auto', sm: 'calc(100% - 12px)'}, display: 'flex', flexDirection: 'column'}}>
              {isNullOrEmpty(chartData.totalPropertyCoverage) && !loading && (
                <ComingSoonCover noData={true} style={{marginTop: 20}} />
              )}
              <CardHeader title="Total Property Coverage Under Advisement" />
              <TotalLCoverChart data={chartData.totalPropertyCoverage} loading={loading} />
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {isLoading ? (
              <Card
                sx={{m: 1, height: {xs: 'auto', sm: 'calc(100% - 12px)'}, display: 'flex', flexDirection: 'column'}}
              >
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                  <CircularProgress />
                </Box>
              </Card>
            ) : (
              <Stack sx={{m: 1, height: 'calc(100% - 12px)', justifyContent: 'space-between'}}>
                <LabelWidget label="Total Clients" data={advisorData.totalClients} sx={{height: '30%'}} />
                <LabelWidget label="Total Families" data={advisorData.totalFamilies} sx={{height: '30%'}} />
                <LabelWidget label="Average client per family" data={advisorData.averageClients} sx={{height: '30%'}} />
              </Stack>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

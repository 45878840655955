/**
 *  id number can be null from canopy data (2023/12/18)
 */
import React from 'react';
import PropTypes from 'prop-types';
import {ImageCarousel} from 'src/components/_dashboard/assets/common';

// mui
import {
  CardContent,
  Dialog,
} from '@mui/material';

// ----------------------------------------------------------------------

ShowIdPage.propTypes = {
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  userId: PropTypes.string,
};


export default function ShowIdPage({ handleClose, open, data, userId }) {
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <CardContent>
        <ImageCarousel data={data} />
      </CardContent>
    </Dialog>
  );
}

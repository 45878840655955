// mui
import {alpha, useTheme} from '@mui/material/styles';
import {GlobalStyles} from '@mui/material';
// utils
import {fCurrency} from 'src/utils/formatNumber';

// ----------------------------------------------------------------------

export function BaseOptionChartStyle() {
  const theme = useTheme();

  const background = {
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72)
  };

  return (
    <GlobalStyles
      styles={{
        '&.apexcharts-canvas': {
          // Tooltip
          '.apexcharts-xaxistooltip': {
            ...background,
            border: 0,
            boxShadow: theme.customShadows.z24,
            color: theme.palette.text.primary,
            borderRadius: theme.shape.borderRadiusSm,
            '&:before': {borderBottomColor: 'transparent'},
            '&:after': {borderBottomColor: alpha(theme.palette.background.default, 0.72)}
          },
          '.apexcharts-tooltip.apexcharts-theme-light': {
            ...background,
            border: 0,
            boxShadow: theme.customShadows.z24,
            borderRadius: theme.shape.borderRadiusSm,
            '& .apexcharts-tooltip-title': {
              border: 0,
              textAlign: 'center',
              fontWeight: theme.typography.fontWeightBold,
              backgroundColor: theme.palette.grey[500_16],
              color: theme.palette.text[theme.palette.mode === 'light' ? 'secondary' : 'primary']
            }
          },
          // Legend
          '.apexcharts-legend': {
            padding: 0
          },
          '.apexcharts-legend-series': {
            display: 'flex !important',
            alignItems: 'center'
          },
          '.apexcharts-legend-marker': {
            marginRight: 8
          },
          '.apexcharts-legend-text': {
            lineHeight: '18px',
            textTransform: 'capitalize'
          }
        }
      }}
    />
  );
}

export default function BaseOptionChart(arg) {
  const {fCurrencyTooltip} = arg || {};
  const theme = useTheme();

  const LABEL_TOTAL = {
    show: true,
    label: 'Total',
    color: theme.palette.text.secondary,
    formatter: function (w) {
      const total = w.globals.seriesTotals.reduce((a, b) => {
        return a + b;
      }, 0.0);
      return !!fCurrencyTooltip ? fCurrency(total) : total;
    },
    ...theme.typography.subtitle2
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: theme.palette.text.primary,
    formatter: function (value) {
      return !!fCurrencyTooltip ? fCurrency(value) : value;
    },
    style: {
      fontSize: '12vw'
    }
  };

  return {
    // Colors
    colors: [
      '#0099ff',
      '#0066ff',
      '#00cc33',
      '#00cc99',
      '#00cccc',
      '#00ccff',
      '#66ccff',
      '#66cccc',
      '#33ffcc',
      '#74dae8',
      '#0b9ac2',
      '#10b7aa',
      '#1c72c0',
      '#0d5fa9',
      '#ccff8c',
      '#81de76',
      '#3a55b4',
      '#6caddf',
      '#8cd9ff',
      '#31f48e',
      '#07aa7b',
      '#0bbeff',
      '#0086ff',
      '#01437d'
    ],

    // Chart
    chart: {
      width: '100%',
      height: '100%',
      toolbar: {show: false},
      zoom: {enabled: false},
      // animations: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily
    },

    // States
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.04
        }
      },
      active: {
        filter: {
          type: 'darken',
          value: 0.88
        }
      }
    },

    // Fill
    fill: {
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100]
      }
    },

    // Datalabels
    dataLabels: {
      enabled: false
    },

    // Stroke
    stroke: {
      width: 3,
      curve: 'smooth',
      lineCap: 'round'
    },

    // Grid
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider
    },

    // Xaxis
    xaxis: {
      axisBorder: {show: false},
      axisTicks: {show: false}
    },

    // Markers
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper
    },

    // Tooltip
    tooltip: {
      y: {
        formatter: function (value) {
          return fCurrencyTooltip ? fCurrency(value) : value;
        }
      },
      x: {
        show: false
      }
    },

    // Legend
    legend: {
      show: true,
      fontSize: '12vw',
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        radius: 12
      },
      fontWeight: 500,
      itemMargin: {horizontal: 12},
      labels: {
        colors: theme.palette.text.primary
      }
    },

    // plotOptions
    plotOptions: {
      // Bar
      bar: {
        columnWidth: '28%',
        borderRadius: 4
      },
      // Pie + Donut
      pie: {
        donut: {
          labels: {
            show: true,
            value: LABEL_VALUE,
            total: LABEL_TOTAL
          }
        }
      },
      // Radialbar
      radialBar: {
        track: {
          strokeWidth: '100%',
          background: theme.palette.grey[500_16]
        },
        dataLabels: {
          value: LABEL_VALUE,
          total: LABEL_TOTAL
        }
      },
      // Radar
      radar: {
        polygons: {
          fill: {colors: ['transparent']},
          strokeColors: theme.palette.divider,
          connectorColors: theme.palette.divider
        }
      },
      // polarArea
      polarArea: {
        rings: {
          strokeColor: theme.palette.divider
        },
        spokes: {
          connectorColors: theme.palette.divider
        }
      }
    },

    // Responsive
    responsive: [
      {
        // sm
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: {bar: {columnWidth: '40%'}}
        }
      },
      {
        // md
        breakpoint: theme.breakpoints.values.md,
        options: {
          plotOptions: {bar: {columnWidth: '32%'}}
        }
      }
    ]
  };
}

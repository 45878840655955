import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {useState, useRef, useEffect} from 'react';
// mui
import {useTheme} from '@mui/material/styles';
import {Box, Card} from '@mui/material';
// components
import EmptyContent from 'src/components/EmptyContent';
import {CarouselControlsArrowsIndex} from 'src/components/carousel/controls';

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object
};

function CarouselItem({item}) {
  const {documentLink, documentTitle} = item;

  return (
    <Box component="img" alt={documentTitle} src={documentLink} sx={{width: '100%', height: 480, objectFit: 'cover'}} />
  );
}

export default function ImageCarousel({data, imageTransform}) {
  const theme = useTheme();
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (_current, next) => setCurrentIndex(next)
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  useEffect(() => {
    setImages(imageTransform ? data.map(imageTransform) : data);
  }, [data, imageTransform]);

  return !!images.length ? (
    <Card>
      <Slider ref={carouselRef} {...settings}>
        {images.map((item) => (
          <CarouselItem key={item.documentTitle} item={item} />
        ))}
      </Slider>

      <CarouselControlsArrowsIndex
        index={currentIndex}
        total={images.length}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </Card>
  ) : (
    <EmptyContent title="No Image data available" />
  );
}

ImageCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  imageTransform: PropTypes.func
};

import PropTypes from 'prop-types';
import {useState} from 'react';
import {useSnackbar} from 'notistack';
// mui
import {Button, CircularProgress, Stack, Typography} from '@mui/material';
// api
import {sendInviteEmail} from 'src/api/advisor';
// redux
import {useDispatch, useSelector} from 'src/redux/store';
import {setList} from 'src/redux/slices/client';
import {updateContactAction} from 'src/redux/slices/contact';
// hooks
import useAuth from 'src/hooks/useAuth';
// utils
import {getFullName} from 'src/utils/createAvatar';
// components
import Label from 'src/components/Label';
// constants
import {getFamilyRole} from 'src/constants/user';
// ----------------------------------------------------------------------

Heading.propTypes = {
  data: PropTypes.array
};

export default function Heading({data = []}) {
  const dispatch = useDispatch();
  const {user} = useAuth();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);

  const isAdvisor = user.role === 'Advisor';
  const {members, familyGroups} = useSelector((state) => state.familyGroup);
  const {list: clients} = useSelector((state) => state.client);
  const {contacts} = useSelector((state) => state.contact);

  const membersSelected = (isAdvisor ? clients : members).filter((m) => m.show);

  const memberName = `${getFullName(membersSelected[0])} In Focus`;
  const membersSelectedIds = members.filter((m) => m.show)?.map?.((m) => m?.id);
  const fam = familyGroups?.filter?.((f) =>
    f?.members?.every?.((m) => membersSelectedIds?.includes(m?.id === 'self' ? user?.userId : m?.id))
  );
  const groupName =
    fam.length > 1 || fam.length === 0
      ? `Your ${isAdvisor ? 'Clients' : 'Family'} In Focus`
      : `${fam?.[0]?.groupName} In Focus`;

  // Invite Email Handler
  const handleInvite = async () => {
    const clientId = clients.find((item) => item.show)?.id;

    if (!!clientId) {
      setLoading(true);
      const payload = {
        receiverUserId: clientId
      };
      const res = await sendInviteEmail(payload);
      if (res.result) {
        enqueueSnackbar('Invite email sent.', {variant: 'success'});

        // update client data for invite status:
        dispatch(setList(clients.map((item) => (item.id === clientId ? {...item, isInviteEmailSent: true} : item))));
        // update contact data for invite status:
        const targetContact = contacts.find((item) => item.userId === clientId);
        if (!!targetContact) dispatch(updateContactAction({...targetContact, isInviteEmailSent: true}));
      } else {
        enqueueSnackbar('There was an error while sending invite email.', {variant: 'error'});
      }
      setLoading(false);
    }
  };

  const showInviteBtn =
    isAdvisor &&
    membersSelected.length === 1 &&
    membersSelected[0].isCreatedByAdvisor &&
    !membersSelected[0].isInviteEmailSent;

  return (
    <Stack direction="row" sx={{mb: 3, alignItems: 'center'}} spacing={3}>
      <Typography variant="h3">
        {membersSelected?.length === 0
          ? 'Your Life In Focus'
          : membersSelected?.length === 1
          ? membersSelected[0]?.id === user?.userId
            ? 'Your Life In Focus'
            : memberName
          : groupName}
      </Typography>
      <Stack direction="row" spacing={1}>
        {data.map((item, index) => (
          <Label key={index} variant="filled" color="info">
            {getFamilyRole(item)}
          </Label>
        ))}
      </Stack>
      {showInviteBtn && (
        <Button variant="contained" onClick={handleInvite} disabled={loading}>
          {!loading ? 'Invite' : <CircularProgress color="inherit" size={20} sx={{color: 'white'}} />}
        </Button>
      )}
    </Stack>
  );
}

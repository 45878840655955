import React from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
// mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Fab,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addOrg} from 'src/redux/slices/organization';
import {addClientOrg} from 'src/redux/slices/client';
// utils
import {formatPhoneNumber, getCleanPhoneNumber} from 'src/utils/stringFormat';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import NumberFormat from 'src/components/NumberFormat';
import {AddressFields, ContactOrgSelect} from 'src/components/_dashboard/common';
// constants
import {EN_TYPE, ORGANIZATION_STATUS} from 'src/constants/dropdown';
// ----------------------------------------------------------------------

EntityDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default function EntityDialog({handleClose, clientId, open}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const EDSchema = Yup.object().shape({
    name: Yup.string(),
    entitiesType: Yup.string(),
    opAgreement: Yup.array()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      entitiesType: '',
      owners: [],
      addressId: '',
      primaryPhone: '',
      website: '',
      revenue: '',
      expense: '',
      status: 'active',
      value: '',
      note: '',
      review: '',
      opAgreement: [], // Operating Agreement
      einFiling: [], // EIN Filing
      empDocs: [] // Employment Documents
    },
    validationSchema: EDSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: 'entity'};
        const res = !!clientId
          ? await dispatch(addClientOrg(payload, clientId, !isAdvisor))
          : await dispatch(addOrg(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('New Org has been created.', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/org/entity/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/org/entity/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Org creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, getFieldProps, setFieldValue, handleSubmit, isSubmitting} = formik;

  

  const handleAddOwner = () => {
    setFieldValue('owners', [...values.owners, {id: '', percentage: ''}]);
  };

  const handleRemoveOwner = (id) => {
    const newOwners = values.owners.filter((item) => item.id !== id);
    setFieldValue('owners', newOwners);
  };

  const handleOwnerPercentChange = (index, val) => {
    const currentValue = [...values.owners];
    currentValue[index].percentage = val;

    setFieldValue('owners', currentValue);
  };

  const handleOwnerChange = (index, val) => {
    const currentValue = [...values.owners];
    currentValue[index].id = val.id;

    setFieldValue('owners', currentValue);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Add New Entity</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('name')}
                  fullWidth
                  type="text"
                  label="Organization Name"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  select
                  fullWidth
                  label="Type"
                  {...getFieldProps('entitiesType')}
                  onChange={(e) => setFieldValue('entitiesType', e.target.value)}
                >
                  {EN_TYPE.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{alignItems: 'center'}}>
                <Typography variant="h6">Owner</Typography>
                <Tooltip title="Add owner">
                  <Fab color="primary" size="small" onClick={handleAddOwner}>
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Stack>
              {!!values.owners.length &&
                values.owners.map((item, index) => (
                  <Stack key={index} direction={{xs: 'column', md: 'row'}} spacing={2} sx={{alignItems: 'center'}}>
                    <ContactOrgSelect
                      value={item.id}
                      label="Owner"
                      handleChange={(val) => handleOwnerChange(index, val)}
                    />
                    <NumberFormat
                      fullWidth
                      label="Percentage"
                      suffix="%"
                      placeholder="0.00%"
                      value={item.percentage}
                      onChange={(e) => handleOwnerPercentChange(index, parseFloat(e.target.values.value))}
                    />
                    <Box>
                      <Fab color="error" size="small" onClick={() => handleRemoveOwner(item.id)}>
                        <RemoveIcon />
                      </Fab>
                    </Box>
                  </Stack>
                ))}
              <AddressFields
                isRealEstate={false}
                addId={values.addressId}
                handleChange={(id) => {
                  setFieldValue('addressId', id);
                }}
                inputProps={{
                  error: Boolean(touched.addressId && errors.addressId),
                  helperText: touched.addressId && errors.addressId
                }}
              />
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <NumberFormat
                  fullWidth
                  label="Revenue"
                  prefix="$"
                  {...getFieldProps('revenue')}
                  onChange={(e) => setFieldValue('revenue', parseFloat(e.target.values.value))}
                />
                <NumberFormat
                  fullWidth
                  label="Expense"
                  prefix="$"
                  {...getFieldProps('expense')}
                  onChange={(e) => setFieldValue('expense', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Organization Status"
                  {...getFieldProps('status')}
                  onChange={(e) => setFieldValue('status', e.target.value)}
                  helperText="Please select status."
                >
                  {ORGANIZATION_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <NumberFormat
                  fullWidth
                  label="Value"
                  prefix="$"
                  placeholder="$0"
                  {...getFieldProps('value')}
                  onChange={(e) => setFieldValue('value', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('website')}
                  fullWidth
                  type="text"
                  label="Website"
                  error={Boolean(touched.website && errors.website)}
                  helperText={touched.website && errors.website}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Primary Phone"
                  value={formatPhoneNumber(values.primaryPhone)}
                  onChange={(e) => setFieldValue('primaryPhone', getCleanPhoneNumber(e.target.value))}
                  error={Boolean(touched.primaryPhone && errors.primaryPhone)}
                  helperText={touched.primaryPhone && errors.primaryPhone}
                />
              </Stack>
              <Stack spacing={2}>
                <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
              </Stack>
              
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}

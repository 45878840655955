import React, { useState, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
import {pick} from 'lodash';
import {useSelector} from 'react-redux';
// mui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  RadioGroup,
  Radio
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addInsurance} from 'src/redux/slices/insurance';
import {addClientInsurance} from 'src/redux/slices/client';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// utils
import {fDateZeroTime} from 'src/utils/formatTime';
// components
import {ContactSelect} from 'src/components/_dashboard/common';
import NumberFormat from 'src/components/NumberFormat';
import DisplayInsuranceList from 'src/components/canopy/DisplayInsuranceList';
import {FormikErrorNotification, TextFieldWithHover} from 'src/components/_dashboard/common';
// constants
import {IMPORT_OPTIONS} from 'src/constants/insurance';
import {IN_TYPE, CLAIM_TYPE, CLAIM_STATUS} from 'src/constants/dropdown';
// ----------------------------------------------------------------------

AddDialog.propTypes = {
  passOptionStep: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  policies: PropTypes.array,
  isLoading: PropTypes.bool,
  RISKS: PropTypes.array,
  handleClose: PropTypes.func.isRequired
};

export default function AddDialog({
  passOptionStep,
  clientId,
  tab,
  isLoading = false,
  inLoading = false,
  policies = [],
  RISKS = [],
  handleClose
}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show && m.id !== user.userId);

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [step, setStep] = useState(passOptionStep ? 2 : 1);
  const [option, setOption] = useState(IMPORT_OPTIONS.manual);

  const AddSchema = Yup.object().shape({
    claimType: Yup.string().isRequired,
    policyType: Yup.string().isRequired,
    policyNumber: Yup.string().required('Policy Number is required'),
    nickName: Yup.string().isRequired,
    claimDocs: Yup.array(),
    dateOfLoss: Yup.date().nullable().min(new Date(1900, 0, 1))
  });

  const formik = useFormik({
    initialValues: {
      policyType: 'realEstate',
      claimType: 'property',
      policyNumber: '', // required
      nickName: '', // required
      dateOfLoss: null, // required
      dateClosed: null, // optional
      deductibleApplied: '', // Deductible Applied
      chargeable: false, // Chargeable
      drivers: [], // Drivers
      subrogated: false, // Subrogated
      subAmount: '', // Amount Subrogated
      amountPaid: '',
      status: 'open',
      note: '', // required
      risks: [],
      relatedPolicies: [], // Related Policy IDS (required)
      claimDocs: [] // Claim Documents
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: tab};
        const res =
          !!clientId || memberSelected.length === 1
            ? await dispatch(addClientInsurance(payload, clientId, !isAdvisor))
            : await dispatch(addInsurance(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('Add Claim success', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/insurance/claim/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/insurance/claim/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Claim creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, resetForm, getFieldProps, setFieldValue, handleSubmit, isSubmitting} = formik;

  

  const filteredPolicies = useMemo(
    () => (policies || []).filter((policy) => policy.type === values.policyType),
    [values.policyType, policies]
  );

  const isManual = option === IMPORT_OPTIONS.manual;

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={isManual || step === 1} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Add New Claim</DialogTitle>
          <Divider />
          <DialogContent>
            {step === 1 ? (
              <RadioGroup name="radio-buttons-group" value={option} onChange={(e) => setOption(e.target.value)}>
                <FormControlLabel value={IMPORT_OPTIONS.manual} control={<Radio />} label="Add Manually" />
                <FormControlLabel value={IMPORT_OPTIONS.carrier} control={<Radio />} label="Import From Carrier" />
              </RadioGroup>
            ) : (
              isManual && (
                <Stack spacing={2}>
                  {/* Top section */}
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField select fullWidth label="Claim Type" {...getFieldProps('claimType')}>
                      {CLAIM_TYPE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      fullWidth
                      label="Policy Type"
                      {...getFieldProps('policyType')}
                      onChange={(e) => {
                        setFieldValue('policyType', e.target.value);
                        setFieldValue('relatedPolicies', []);
                      }}
                      error={Boolean(touched.policyType && errors.policyType)}
                      helperText={touched.policyType && errors.policyType}
                    >
                      {IN_TYPE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextFieldWithHover
                      {...getFieldProps('nickName')}
                      fullWidth
                      type="string"
                      label="Claim Name"
                      info="Input the claim name"
                    />
                    <TextField
                      {...getFieldProps('policyNumber')}
                      fullWidth
                      type="text"
                      label="Claim Number"
                      error={Boolean(touched.policyNumber && errors.policyNumber)}
                      helperText={touched.policyNumber && errors.policyNumber}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2}>
                    <DatePicker
                      fullWidth
                      label="Date of Loss"
                      {...getFieldProps('dateOfLoss')}
                      onChange={(date) => {
                        setFieldValue('dateOfLoss', fDateZeroTime(date));
                      }}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                    <DatePicker
                      fullWidth
                      label="Date Closed"
                      {...getFieldProps('dateClosed')}
                      onChange={(date) => {
                        setFieldValue('dateClosed', fDateZeroTime(date));
                      }}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                    <NumberFormat
                      fullWidth
                      label="Deductible Applied"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('deductibleApplied')}
                      onChange={(e) => setFieldValue('deductibleApplied', parseFloat(e.target.values.value))}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2}>
                    <Box sx={{width: '100%'}}>
                      <FormControlLabel
                        control={<Switch {...getFieldProps('chargeable')} checked={values.chargeable} />}
                        label="Chargeable"
                        labelPlacement="start"
                      />
                    </Box>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="drivers"
                      label="Drivers"
                      values={values.drivers}
                      setFieldValue={setFieldValue}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2}>
                    <Box>
                      <FormControlLabel
                        control={<Switch {...getFieldProps('subrogated')} checked={values.subrogated} />}
                        label="Subrogated"
                        labelPlacement="start"
                      />
                    </Box>
                    {values.subrogated && (
                      <NumberFormat
                        fullWidth
                        label="Amount Subrogated"
                        prefix="$"
                        placeholder="$0.00"
                        {...getFieldProps('subAmount')}
                        onChange={(e) => setFieldValue('subAmount', parseFloat(e.target.values.value))}
                      />
                    )}
                  </Stack>
                  {/* Middle section - coverage limits */}
                  <Divider />
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <NumberFormat
                      fullWidth
                      label="Amount Paid"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('amountPaid')}
                      onChange={(e) => setFieldValue('amountPaid', parseFloat(e.target.values.value))}
                    />
                    <TextField
                      select
                      fullWidth
                      label="Status"
                      {...getFieldProps('status')}
                      onChange={(e) => setFieldValue('status', e.target.value)}
                    >
                      {CLAIM_STATUS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  {/* Bottom section */}
                  <Divider />
                  <Stack spacing={2}>
                    <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Autocomplete
                      loading={isLoading}
                      fullWidth
                      multiple
                      options={RISKS.filter((risk) => risk.type === values.policyType)}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.nickName || option.id}
                      value={RISKS.filter((item) => values.risks.some((value) => value.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'risks',
                          value.map((item) => ({id: item.id, type: item.type}))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {option.nickName || option.id}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Risk" placeholder="Select Risk" />}
                    />
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={filteredPolicies}
                      disableCloseOnSelect
                      getOptionLabel={(option) => `${option.type} - ${option.policyNumber}`}
                      value={policies.filter((item) => values.relatedPolicies.some((policy) => policy.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'relatedPolicies',
                          value.map((item) => pick(item, ['id', 'type']))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {`${option.type} - ${option.policyNumber}`}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Policies" />}
                    />
                  </Stack>
                  
                </Stack>
              )
            )}
          </DialogContent>
          {step === 1 ? (
            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  handleClose();
                  setStep(1);
                }}
                color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={() => setStep(2)}>
                Next
              </LoadingButton>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => setStep(1)} color="inherit">
                Back
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
                Submit
              </LoadingButton>
            </DialogActions>
          )}
        </Dialog>
      </Form>
      {step === 2 && option === IMPORT_OPTIONS.carrier && (
        <DisplayInsuranceList handleReset={() => setStep(1)} handleClose={handleClose} />
      )}
    </FormikProvider>
  );
}

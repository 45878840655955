export const LIAB_MGMT = [
  {key: 'primaryLiability', label: 'Primary Liability'},
  {key: 'umbrellaLiability', label: 'Umbrella Liability'},
  {key: 'automobileLiability', label: 'Automobile Liability'},
  {key: 'watercraftLiability', label: 'Watercraft Liability'},
  {key: 'aviationLiability', label: 'Aviation Liability'},
  {key: 'professionalLiability', label: 'Professional Liability'}
];
export const PRO_PROTECTION = [
  {key: 'realEstate', label: 'Real Estate'},
  {key: 'floodInsurance', label: 'Flood Insurance'},
  {key: 'valuableArticlesInsurance', label: 'Valuable Articles Insurance'},
  {key: 'automobileFleet', label: 'Automobile Fleet'},
  {key: 'watercraftFleet', label: 'Watercraft Fleet'},
  {key: 'aircraftFleet', label: 'Aircraft Fleet'},
  {key: 'equineMortality', label: 'Equine Mortality'}
];

export const LIFE_ACCIDENT = [
  {key: 'lifeInsurance', label: 'Life Insurance'},
  {key: 'longTermCare', label: 'Long Term Care'},
  {key: 'travelAccidentHealth', label: 'Travel Accident & Health'},
  {key: 'disability', label: 'Disability'}
];

export const EMPLOYMENT_PRACTICES = [
  {key: 'directorsOfficersLiability', label: 'Directors & Officers Liability'},
  {key: 'employmentPracticesLiability', label: 'Employment Practices Liability'},
  {key: 'workersCompensation', label: 'Workers Compensation'}
];

export const CYBER_RISK = [
  {key: 'identityCredit', label: 'Identity / Credit Monitoring'},
  {key: 'cybInsurPer', label: 'Cybersecurity Insurance (personal)'},
  {key: 'cybInsurCom', label: 'Cybersecurity Insurance (commercial)'},
  {key: 'actMonService', label: 'Active Monitoring Service'},
  {key: 'froCreScores', label: 'Frozen Credit Scores (all bureaus)'}
];

export const INIT_RISK = [
  ...LIAB_MGMT,
  ...PRO_PROTECTION,
  ...LIFE_ACCIDENT,
  ...EMPLOYMENT_PRACTICES,
  ...CYBER_RISK
].reduce((result, item) => ({...result, [item.key]: false}), {});

export const CHART_LABELS = [
  {label: 'Real Estate', key: 'realEstate', color: '#0099ff'},
  {label: 'Collections', key: 'collection', color: '#0066ff'},
  {label: 'Automobile', key: 'automobile', color: '#00cc33'},
  {label: 'Watercraft', key: 'watercraft', color: '#00cc99'},
  {label: 'Aviation', key: 'aircraft', color: '#00cccc'},
  {label: 'Equine', key: 'equine', color: '#00ccff'},
  {label: 'Umbrella', key: 'umbrella', color: '#66ccff'}
];
const DEFAULT_VALUE = {
  labels: ['Series-1', 'Series-2'],
  series: [10, 20],
  colors: ['#0099ff', '#0066ff']
};

export const getActiveInfo = (data) => {
  let labels = [];
  let series = [];
  let colors = [];

  CHART_LABELS.forEach(({key, label, color}) => {
    if (!!data && !!data[key]) {
      labels.push(label);
      series.push(data[key]);
      colors.push(color);
    }
  });

  return !!labels.length ? {labels, series, colors} : DEFAULT_VALUE;
};

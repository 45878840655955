import {merge} from 'lodash';
import {useState} from 'react';
import ReactApexChart from 'react-apexcharts';
// utils
import {fCurrency, fShortCurrency} from 'src/utils/formatNumber';
import {isNullOrEmpty} from 'src/utils/checkEmpty';
import {capitalCase} from 'change-case';
// component
import BaseOptionChart from './BaseOptionChart';
// mui
import {Box, CircularProgress, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
// ----------------------------------------------------------------------

const CHART_LABEL = ['Series 1', 'Series 2'];
const CHART_DATA = [
  {2019: {total: 1200}, 2020: {total: 500}, 2021: {total: 100}},
  {2019: {total: 1000}, 2020: {total: 3100}, 2021: {total: 1600}}
];

const getDataByYear = (data) => {
  /**
   * data: {realEstate: [{year: 2021, value: 100}, {year: 2022, value: 500}], automobile: [{year: 2019, value: 3400}], ...}
   */
  const yearData = {};
  /**
   * yearData: {2021: {total: 19000, realEstate: 19000}, 2022: {total: 323423, collection: 323413, automobile: 10}}
   */
  for (const category in data) {
    // category: realEstate, autombile, aircraft, ...
    data[category]?.forEach((entry) => {
      const year = entry.year;
      const value = entry.value;

      if (!yearData[year]) {
        yearData[year] = {total: value, [category]: value};
      } else {
        yearData[year] = {...yearData[year], total: yearData[year].total + value, [category]: value};
      }
    });
  }

  return yearData;
};

const getAllYears = (data1 = {}, data2 = {}) => {
  let years = [];
  for (let category in data1) {
    data1[category]?.forEach((entry) => {
      if (!years.includes(entry.year)) {
        years.push(entry.year);
      }
    });
  }
  for (let category in data2) {
    data2[category]?.forEach((entry) => {
      if (!years.includes(entry.year)) {
        years.push(entry.year);
      }
    });
  }

  years.sort((a, b) => parseInt(a) - parseInt(b));
  return years;
};
// ----------------------------------------------------------------------

export default function TotalInsuranceColumnChart({data1 = {}, data2 = {}, label: inputLabel, loading}) {
  const isDataEmpty = isNullOrEmpty(data1) && isNullOrEmpty(data2);
  const [categories, data, label] = isDataEmpty
    ? [[2019, 2020, 2021], CHART_DATA, CHART_LABEL]
    : [getAllYears(data1, data2), [getDataByYear(data1), getDataByYear(data2)], inputLabel];

  const series = label.map?.((lb, index) => ({
    name: lb,
    data: categories.map((year) => {
      return {x: '' + year, y: data[index][year]?.total || 0, details: data[index][year]};
    })
  }));

  const chartOptions = merge(BaseOptionChart(), {
    stroke: {show: false},
    yaxis: series.map((axis, idx) => ({
      seriesName: axis.name,
      opposite: !!idx % 2,
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true
      },
      title: {
        text: axis.name
      },
      labels: {
        formatter: (val) => fShortCurrency(val)
      }
    })),
    tooltip: {
      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        const total = series[seriesIndex][dataPointIndex] || 0;
        const detail = w.globals.initialSeries[seriesIndex].data[dataPointIndex].details;
        /**
         * {realEstate: [{year: 2021: value: 34234}], automobile: []...}
         */
        const customTooltip = Object.keys(detail)
          .filter((key) => key !== 'total')
          .map((key) => ({
            name: capitalCase(key),
            data: detail[key]
          }))
          ?.map(({name, data}) =>
            data > 0
              ? `<div style="margin-bottom: 8px;"><span style="font-weight: bold;">${name}</span>: ${fCurrency(
                  data
                )}</div>`
              : ''
          )
          .join('');

        return `<div style="padding: 10px; border-radius: 10px;">${customTooltip}
            <hr/>
            <div style="margin-top: 8px;">
              <span style="font-weight: bold;">Total ${
                seriesIndex === 0 ? 'Premium' : 'Property Value'
              }</span>: ${fCurrency(total)}
            </div>
          </div>`;
      }
    },
    legend: {show: true}
  });

  const [selectedPeriod, setSelectedPeriod] = useState('AY');

  const selectedSeries = [...series];
  if (selectedPeriod === '3Y') {
    for (let item of selectedSeries) {
      item.data = item.data.slice(item.data.length - 3);
    }
  } else if (selectedPeriod === '5Y') {
    for (let item of selectedSeries) {
      item.data = item.data.slice(item.data.length - 5);
    }
  }

  return loading ? (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '260px'}}>
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{flex: 1, ...(isDataEmpty && {filter: 'grayscale(90%)', opacity: 0.5})}}>
      <FormControl
        sx={{
          mb: 2,
          minWidth: 120,
          position: 'absolute',
          top: '-56px',
          right: 105,
          marginBottom: 0,
          marginTop: '3px',
          zIndex: 1
        }}
        size="small"
      >
        <InputLabel>Period</InputLabel>
        <Select
          sx={{height: 30, width: 120}}
          value={selectedPeriod}
          onChange={(e) => setSelectedPeriod(e.target.value)}
          label="Period"
        >
          <MenuItem value="3Y">3 Years</MenuItem>
          <MenuItem value="5Y">5 Years</MenuItem>
          <MenuItem value="AY">All Years</MenuItem>
        </Select>
      </FormControl>
      <ReactApexChart height={'100%'} type="bar" series={selectedSeries} options={chartOptions} />
    </Box>
  );
}

import axios from 'src/utils/axios';
// ----------------------------------------------------------------------

// Dashboard - General APP
export async function getAdvisorDashboardData(isFamilyGroup = false) {
  try {
    const response = await axios.get(`/advisor/dashboard?isFamilyGroup=${isFamilyGroup}`);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getAdvisorFamilyInfo() {
  try {
    const response = await axios.get('/advisor/profile/family/all');
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateAdvisorFamilyInfo(payload, clientId) {
  try {
    const response = await axios.post(`/advisor/profile/family?userId=${clientId}`, payload);

    return response;
  } catch (e) {
    return e;
  }
}

export async function getAdvisorFinancialInfo() {
  try {
    const response = await axios.get('/advisor/profile/financials/all');
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateAdvisorFinancialInfo(payload, clientId) {
  try {
    const response = await axios.post(`/advisor/profile/financials?userId=${clientId}`, payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getAdvisorTaxDeadline() {
  try {
    const response = await axios.get('/advisor/profile/tax/all');
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateAdvisorTaxDeadline(payload, clientId) {
  try {
    const response = await axios.post(`/advisor/profile/tax?userId=${clientId}`, payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getAdvisorRiskScore(isFamilyGroup = false) {
  try {
    const response = await axios.get(`/advisor/risk-score-card/all?isFamilyGroup=${isFamilyGroup}`);
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateAdvisorRiskScore(payload, clientId) {
  try {
    const response = await axios.put(`/advisor/risk-score-card?userId=${clientId}`, payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getAdvisorDLInfo() {
  try {
    const response = await axios.get('/advisor/profile/driver-license/all');
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateAdvisorDLInfo(payload, clientId) {
  try {
    const response = await axios.post(`/advisor/profile/driver-license/?userId=${clientId}`, payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getAdvisorPassport() {
  try {
    const response = await axios.get('/advisor/profile/passport/all');
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateAdvisorPassport(payload, clientId) {
  try {
    const response = await axios.post(`/advisor/profile/passport?userId=${clientId}`, payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getAdvisorCLInfo() {
  try {
    const response = await axios.get('/advisor/profile/captain-license/all');
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateAdvisorCLInfo(payload, clientId) {
  try {
    const response = await axios.post(`/advisor/profile/captain-license?userId=${clientId}`, payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getAdvisorPLInfo() {
  try {
    const response = await axios.get('/advisor/profile/pilot-license/all');
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateAdvisorPLInfo(payload, clientId) {
  try {
    const response = await axios.post(`/advisor/profile/pilot-license?userId=${clientId}`, payload);
    return response;
  } catch (e) {
    return e;
  }
}

// Send invite email
export async function sendInviteEmail(payload) {
  try {
    const response = await axios.post(`/advisor/client-email-invite`, payload);
    return response.data;
  } catch (e) {
    return e;
  }
}

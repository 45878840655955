import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
// mui
import {Box, CircularProgress} from '@mui/material';
// components
import BaseOptionChart from './BaseOptionChart';
import {isNullOrEmpty} from 'src/utils/checkEmpty';
// constants
import {getActiveInfo} from './labels';
// ----------------------------------------------------------------------

export default function TotalLCoverChart({data, loading}) {
  const isDataEmpty = isNullOrEmpty(data);
  const {labels, series, colors} = getActiveInfo(data);

  const chartOptions = merge(BaseOptionChart({fCurrencyTooltip: true}), {
    colors,
    labels,
    stroke: {show: false},
    legend: {
      show: true
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          ...(isDataEmpty && {
            labels: {
              show: false,
              total: false
            }
          })
        }
      }
    }
  });

  return (
    <Box sx={{flex: 1}}>
      {loading ? (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '260px'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            ...(isDataEmpty && {filter: 'grayscale(90%)', opacity: 0.2})
          }}
        >
          <ReactApexChart type="donut" series={series} options={chartOptions} />
        </Box>
      )}
    </Box>
  );
}

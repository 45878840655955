import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalCase } from 'change-case';
// mui
import { Card, CircularProgress, Stack, Typography ,Box} from '@mui/material';
// api
import { getAssetDetail } from 'src/api';
// hooks
import useAuth from 'src/hooks/useAuth';
// constants
import { getIcon } from 'src/utils/items';
import { Link } from '@mui/icons-material';
import { ShowIdPage } from '../assets/components/Automobile';
// ----------------------------------------------------------------------

RiskDetails.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  userId: PropTypes.string,
  showType: PropTypes.bool
};

RiskDetails.defaultProps = {
  userId: ''
};

export default function RiskDetails({ type, id, userId, showType = true, item = [] }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const isAdvisor = user.role === 'Advisor';
  const [open, setOpen] = useState(false);

  const [riskDetail, setRiskDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const icon = getIcon('asset', type);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const data = await getAssetDetail(type, id, userId, !isAdvisor);
        if (isMounted) {
          if (data && data.id) {
            setRiskDetail(`${showType ? capitalCase(type) + ': ' : ''}${data.nickName}`);
          }
        }
      } catch (err) {
        console.log('Risk-card: ', err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (!!id && !!type) fetchData();
    return () => {
      isMounted = false;
    };
  }, [id, userId, type, showType, isAdvisor]);

  const handleClick = () => {
    navigate(!userId ? `/dashboard/asset/${type}/${id}` : `/dashboard/asset/${type}/${id}/${userId}`);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return !riskDetail ? null : (
    <Card sx={{ cursor: 'pointer', px: 2, py: 1 }}>
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        {/* Icon will trigger handleClick */}
        <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
          {icon}
        </Box>

        {/* Title will trigger handleClick */}
        <Typography onClick={handleClick} sx={{ cursor: 'pointer' }}>
          {riskDetail}
        </Typography>

        {/* Link will only open the modal, not trigger handleClick */}
        <Link
          sx={{ ml: 'auto' }}
          onClick={(event) => {
            event.preventDefault();  // Prevent the default behavior of the link
            event.stopPropagation(); // Prevent propagation to parent elements
            setOpen(true);           // Open the modal
          }}
        >
          View ID
        </Link>
      </Stack>

      {/* Modal component */}
      <ShowIdPage open={open} handleClose={handleClose} data={item} />
    </Card>

  );
}

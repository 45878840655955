import React , { useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {capitalCase} from 'change-case';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
import {useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';
// mui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import AddIcon from '@mui/icons-material/Add';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addInsurance} from 'src/redux/slices/insurance';
import {addClientInsurance} from 'src/redux/slices/client';
// utils
import {fCurrency} from 'src/utils/formatNumber';
import {fDate, fDateZeroTime} from 'src/utils/formatTime';
import {getInsStatusByDate} from 'src/utils/getInsStatusByDate';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import Label from 'src/components/Label';
import NumberFormat from 'src/components/NumberFormat';
import SwitchWithLabels from 'src/components/SwitchWithLabels';
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
import DisplayInsuranceList from 'src/components/canopy/DisplayInsuranceList';
import {ContactSelect, FormikErrorNotification, TextFieldWithHover} from 'src/components/_dashboard/common';
// constants
import {IMPORT_OPTIONS} from 'src/constants/insurance';
import {IN_RE_TYPE, INSURANCE_STATUS} from 'src/constants/dropdown';
// ----------------------------------------------------------------------
const SELECT_ALL_RISK = {id: 'all', nickName: 'Select All'}; // NOTE: Options to deselect all risk
const DESELECT_ALL_RISK = {id: 'clear', nickName: 'Deselect All'}; // NOTE: Options to deselect all risk

AddDialog.propTypes = {
  passOptionStep: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  inLoading: PropTypes.bool,
  RISKS: PropTypes.array,
  policies: PropTypes.array,
  CLAIMS: PropTypes.array,
  handleClose: PropTypes.func.isRequired
};

export default function AddDialog({
  passOptionStep,
  clientId,
  tab,
  isLoading = false,
  inLoading = false,
  RISKS = [],
  policies = [],
  CLAIMS = [],
  handleClose
}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show && m.id !== user.userId);

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [step, setStep] = useState(passOptionStep ? 2 : 1);
  const [option, setOption] = useState(IMPORT_OPTIONS.manual);

  const AddSchema = Yup.object().shape({
    policyType: Yup.string().isRequired,
    policyNumber: Yup.string().required('Policy Number is required'),
    policyDocs: Yup.array(),
    effDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    expDate: Yup.date().typeError('Expiration Date is required.').min(new Date(1900, 0, 1))
  });
  const noLCoverageTypes = IN_RE_TYPE.filter((item) => item.noLCoverage).map((item) => item.value);

  const formik = useFormik({
    initialValues: {
      policyType: '',
      policyNumber: '',
      nickName: '',
      carrier: '',
      broker: '',
      risks: [],
      primaryContact: [],
      issuingCompany: '',
      namedInsured: [],
      additionalInsured: [],
      effDate: null,
      expDate: null,
      premium: '',
      dwelling: '',
      other: '', // Other Structures
      pProperty: '', // Personal Property
      lUse: '', // Loss of Use/Rent
      lAss: '', // Loss of Assessments
      lCoverage: '', // Liability Coverage
      medPayment: '', // Medical Payments
      rebuilding: '', // Rebuilding to Code: Came from Canopy doc
      showDWaiver: false, // Switch to show Deductible Waiver
      deductible: '', // Deductible Price
      showHDWaiver: false, // Switch to show Hurricane Deductible Waiver
      huDeductible: '', // Deductible Price (in percentage of dwelling price)
      showEQDWaiver: false, // Switch to show Earthquake Deductible Waiver
      eqDeductible: '', // Earthquake Deductible Price (in percentage of dwelling price)
      includesSinkholeCoverage: false,
      sinkholeDeductible: '',
      includesWildFireCoverage: false,
      applyForAllWind: false, // Switch to show Apply for All Wind/Hail damage
      wildFireDeductible: '',
      status: 'active',
      rPolicies: [], // Related Policy IDS
      rClaims: [], // Related Claim IDS
      policyDocs: [],
      note: ''
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: tab};
        const res =
          !!clientId || memberSelected.length === 1
            ? await dispatch(addClientInsurance(payload, clientId, !isAdvisor))
            : await dispatch(addInsurance(payload));
        setSubmitting(false);

        if (res && res?.data) {
          enqueueSnackbar('Add Insurance success', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/insurance/realEstate/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/insurance/realEstate/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 201 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Insurance creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, resetForm, getFieldProps, setFieldValue, setValues, handleSubmit, isSubmitting} =
    formik;

  

  const handleInsStatusByEffecDate = (date) => {
    setValues({
      ...values,
      effDate: fDateZeroTime(date),
      status: getInsStatusByDate(date, values, 'effDate')
    });
  };

  const handleInsStatusByExpDate = (date) => {
    setValues({
      ...values,
      expDate: fDateZeroTime(date),
      status: getInsStatusByDate(date, values, 'expDate')
    });
  };

  const handleCoverageChange = (riskId, key, value) => {
    let newCoverageValue = cloneDeep(values.risks);
    const target = newCoverageValue.find((item) => item.id === riskId);
    if (target) {
      target[key] = value;
      const totalPremium = newCoverageValue.reduce((res, item) => res + (Number(item.premium) || 0), 0);

      setValues({...values, risks: newCoverageValue, premium: totalPremium});
    }
  };

  const getRiskLabel = (riskId) => {
    const targetRisk = RISKS.find((item) => item.id === riskId);

    return targetRisk ? targetRisk.nickName || targetRisk.id : riskId;
  };

  const handleRiskSelect = (value) => {
    let selectedRisks = [];
    if (value.find((item) => item.id === 'all')) {
      selectedRisks = RISKS;
    } else if (!value.find((item) => item.id === 'clear')) {
      selectedRisks = value;
    }

    const newCoverageValue = selectedRisks.map((risk) => {
      const targetRisk = values.risks.find((item) => item.id === risk.id);

      return targetRisk
        ? targetRisk
        : {
            id: risk.id,
            name: getRiskLabel(risk.id),
            type: risk.type,
            additionalInsured: [],
            premium: '',
            dwelling: '',
            other: '',
            pProperty: '',
            showDWaiver: false,
            deductible: '',
            showHDWaiver: false,
            huDeductible: '',
            showEQDWaiver: false,
            eqDeductible: '',
            includesSinkholeCoverage: false,
            sinkholeDeductible: '',
            includesWildFireCoverage: false,
            wildFireDeductible: '',
            applyForAllWind: false
          };
    });

    const totalPremium = newCoverageValue.reduce((res, item) => res + Number(item.premium), 0);
    setValues({...values, risks: newCoverageValue, premium: totalPremium});
  };

  const isAllRiskSelected = values.risks.length === RISKS.length;
  const isManual = option === IMPORT_OPTIONS.manual;

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={isManual || step === 1} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>{`Add New Insurance${isManual ? ' (Real Estate)' : ''}`}</DialogTitle>
          <Divider />
          <DialogContent>
            {step === 1 ? (
              <RadioGroup name="radio-buttons-group" value={option} onChange={(e) => setOption(e.target.value)}>
                <FormControlLabel value={IMPORT_OPTIONS.manual} control={<Radio />} label="Add Manually" />
                <FormControlLabel value={IMPORT_OPTIONS.carrier} control={<Radio />} label="Import From Carrier" />
              </RadioGroup>
            ) : (
              isManual && (
                <Stack spacing={2}>
                  {/* Top section - identify policy, select risk */}
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Policy Type"
                      {...getFieldProps('policyType')}
                      onChange={(e) => setFieldValue('policyType', e.target.value)}
                      error={Boolean(touched.policyType && errors.policyType)}
                      helperText={touched.policyType && errors.policyType}
                    >
                      {IN_RE_TYPE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      {...getFieldProps('policyNumber')}
                      fullWidth
                      type="text"
                      label="Policy Number"
                      error={Boolean(touched.policyNumber && errors.policyNumber)}
                      helperText={touched.policyNumber && errors.policyNumber}
                    />
                    <TextFieldWithHover
                      {...getFieldProps('nickName')}
                      fullWidth
                      type="string"
                      label="Policy Name"
                      info="Input the policy name"
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="primaryContact"
                      label="Primary Contact"
                      values={values.primaryContact}
                      setFieldValue={setFieldValue}
                      isPrimary={true}
                    />
                    <TextField
                      {...getFieldProps('carrier')}
                      fullWidth
                      type="text"
                      label="Insurance Carrier"
                      error={Boolean(touched.carrier && errors.carrier)}
                      helperText={touched.carrier && errors.carrier}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      {...getFieldProps('broker')}
                      fullWidth
                      type="text"
                      label="Brokerage"
                      error={Boolean(touched.broker && errors.broker)}
                      helperText={touched.broker && errors.broker}
                    />
                    <TextField
                      {...getFieldProps('issuingCompany')}
                      fullWidth
                      type="text"
                      label="Issuing Company"
                      error={Boolean(touched.issuingCompany && errors.issuingCompany)}
                      helperText={touched.issuingCompany && errors.issuingCompany}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2}>
                    <DatePicker
                      fullWidth
                      label="Effective Date"
                      {...getFieldProps('effDate')}
                      onChange={handleInsStatusByEffecDate}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={Boolean(touched.effDate && errors.effDate)}
                          helperText={touched.effDate && errors.effDate}
                        />
                      )}
                    />
                    <DatePicker
                      fullWidth
                      label="Expiration Date*"
                      {...getFieldProps('expDate')}
                      onChange={handleInsStatusByExpDate}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={Boolean(touched.expDate && errors.expDate)}
                          helperText={touched.expDate && errors.expDate}
                        />
                      )}
                    />
                    <StatusLabel status={values.status} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="namedInsured"
                      label="Named Insured"
                      values={values.namedInsured}
                      setFieldValue={setFieldValue}
                    />
                  </Stack>
                  {/* Middle section - coverage limits */}
                  <Divider />
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{alignItems: 'center'}}>
                    <NumberFormat
                      fullWidth
                      label="Total Premium"
                      prefix="$"
                      placeholder="$0.00"
                      value={values.premium}
                      disabled
                    />
                    <Autocomplete
                      loading={isLoading}
                      fullWidth
                      multiple
                      limitTags={3}
                      options={[isAllRiskSelected ? DESELECT_ALL_RISK : SELECT_ALL_RISK, ...RISKS]}
                      sx={{width: '70%'}}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.nickName || option.id}
                      value={RISKS.filter((item) => values.risks.some((value) => value.id === item.id))}
                      onChange={(e, value) => handleRiskSelect(value)}
                      renderOption={(props, option, {selected}) => (
                        <li {...props} key={option.id}>
                          <Checkbox checked={selected} />
                          {option.nickName || option.id}
                        </li>
                      )}
                      renderInput={(params) => <TextField {...params} label="Risk" placeholder="Select Risk" />}
                    />
                    <Box>
                      <Tooltip title="Add new asset">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            navigate('/dashboard/asset', {state: {view: 'realEstate', showModal: true, goBack: true}});
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                  {values.risks.map((risk) => (
                    <Stack spacing={1} key={risk.id}>
                      <Box>
                        <Label variant={'outlined'} color={'primary'}>
                          {getRiskLabel(risk.id)}
                        </Label>
                      </Box>
                      <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                        <ContactSelect
                          clientId={clientId}
                          keyValue="additionalInsured"
                          label="Additional Insured"
                          values={risk.additionalInsured || []}
                          setFieldValue={(key, value) => handleCoverageChange(risk.id, key, value)}
                        />
                        <NumberFormat
                          fullWidth
                          label="Premium"
                          prefix="$"
                          placeholder="$0.00"
                          value={risk.premium}
                          onChange={(e) => handleCoverageChange(risk.id, 'premium', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="Dwelling"
                          prefix="$"
                          placeholder="$0.00"
                          value={risk.dwelling}
                          onChange={(e) => handleCoverageChange(risk.id, 'dwelling', parseFloat(e.target.values.value))}
                        />
                      </Stack>
                      <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                        <NumberFormat
                          fullWidth
                          label="Other Structures"
                          prefix="$"
                          placeholder="$0.00"
                          value={risk.other}
                          onChange={(e) => handleCoverageChange(risk.id, 'other', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="Personal Property"
                          prefix="$"
                          placeholder="$0.00"
                          value={risk.pProperty}
                          onChange={(e) =>
                            handleCoverageChange(risk.id, 'pProperty', parseFloat(e.target.values.value))
                          }
                        />
                      </Stack>
                      <Stack direction={{xs: 'column', md: 'row'}} sx={{justifyContent: 'space-between'}} spacing={2}>
                        <Stack spacing={2} sx={{width: '100%'}}>
                          <Box>
                            <SwitchWithLabels
                              label="Deductible Waiver"
                              onLabel="Yes"
                              offLabel="No"
                              value={risk.showDWaiver}
                              handleChange={(value) => handleCoverageChange(risk.id, 'showDWaiver', value)}
                            />
                          </Box>
                          <NumberFormat
                            fullWidth
                            label="Deductible"
                            prefix="$"
                            placeholder="$0.00"
                            value={risk.deductible}
                            onChange={(e) =>
                              handleCoverageChange(risk.id, 'deductible', parseFloat(e.target.values.value))
                            }
                          />
                        </Stack>
                        <Stack spacing={2} sx={{width: '100%'}}>
                          <Box>
                            <SwitchWithLabels
                              label="Includes Hurricane Coverage"
                              onLabel="Yes"
                              offLabel="No"
                              value={risk.showHDWaiver}
                              handleChange={(value) => handleCoverageChange(risk.id, 'showHDWaiver', value)}
                            />
                          </Box>
                          <Stack spacing={1}>
                            <NumberFormat
                              fullWidth
                              label="Hurricane Deductible"
                              suffix="%"
                              placeholder="0.00%"
                              value={risk.huDeductible}
                              onChange={(e) =>
                                handleCoverageChange(risk.id, 'huDeductible', parseFloat(e.target.values.value))
                              }
                            />
                            {risk.showHDWaiver && (
                              <Typography variant="body1">
                                {risk.huDeductible
                                  ? fCurrency((risk.huDeductible * risk.dwelling) / 100)
                                  : fCurrency(risk.deductible)}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                        <Stack spacing={2} sx={{width: '100%'}}>
                          <Box>
                            <SwitchWithLabels
                              label="Applies for all wind/hail damage"
                              onLabel="Yes"
                              offLabel="No"
                              value={risk.applyForAllWind}
                              handleChange={(value) => handleCoverageChange(risk.id, 'applyForAllWind', value)}
                            />
                          </Box>
                        </Stack>
                      </Stack>
                      <Stack direction={{xs: 'column', md: 'row'}} sx={{justifyContent: 'space-between'}} spacing={2}>
                        <Stack spacing={2} sx={{width: '100%'}}>
                          <Box>
                            <SwitchWithLabels
                              label="Includes Sinkhole Coverage"
                              onLabel="Yes"
                              offLabel="No"
                              value={risk.includesSinkholeCoverage}
                              handleChange={(value) => handleCoverageChange(risk.id, 'includesSinkholeCoverage', value)}
                            />
                          </Box>
                          <Stack spacing={1}>
                            <NumberFormat
                              fullWidth
                              label="Sinkhole Deductible"
                              suffix="%"
                              placeholder="0.00%"
                              max={100}
                              min={0}
                              value={risk.sinkholeDeductible}
                              onChange={(e) =>
                                handleCoverageChange(risk.id, 'sinkholeDeductible', parseFloat(e.target.values.value))
                              }
                            />
                            {values.includesSinkholeCoverage && (
                              <Typography variant="body1">
                                {values.sinkholeDeductible
                                  ? fCurrency((values.sinkholeDeductible * values.dwelling) / 100)
                                  : fCurrency(values.deductible)}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                        <Stack spacing={2} sx={{width: '100%'}}>
                          <Box>
                            <SwitchWithLabels
                              label="Includes Wildfire Coverage"
                              onLabel="Yes"
                              offLabel="No"
                              value={risk.includesWildFireCoverage}
                              handleChange={(value) => handleCoverageChange(risk.id, 'includesWildFireCoverage', value)}
                            />
                          </Box>
                          <Stack spacing={1}>
                            <NumberFormat
                              fullWidth
                              label="Wildfire Deductible"
                              suffix="%"
                              placeholder="0.00%"
                              max={100}
                              min={0}
                              value={risk.wildFireDeductible}
                              onChange={(e) =>
                                handleCoverageChange(risk.id, 'wildFireDeductible', parseFloat(e.target.values.value))
                              }
                            />
                            {risk.includesWildFireCoverage && (
                              <Typography variant="body1">
                                {risk.wildFireDeductible
                                  ? fCurrency((risk.wildFireDeductible * risk.dwelling) / 100)
                                  : fCurrency(risk.deductible)}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                        <Stack spacing={2} sx={{width: '100%'}}>
                          <Box>
                            <SwitchWithLabels
                              label="Includes Earthquake Coverage"
                              onLabel="Yes"
                              offLabel="No"
                              value={risk.showEQDWaiver}
                              handleChange={(value) => handleCoverageChange(risk.id, 'showEQDWaiver', value)}
                            />
                          </Box>
                          <Stack spacing={1}>
                            <NumberFormat
                              fullWidth
                              label="Earthquake Deductible"
                              suffix="%"
                              placeholder="0.00%"
                              max={100}
                              min={0}
                              value={risk.eqDeductible}
                              onChange={(e) =>
                                handleCoverageChange(risk.id, 'eqDeductible', parseFloat(e.target.values.value))
                              }
                            />
                            {risk.showEQDWaiver && (
                              <Typography variant="body1">
                                {risk.eqDeductible
                                  ? fCurrency((risk.eqDeductible * risk.dwelling) / 100)
                                  : fCurrency(risk.deductible)}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
                  <Divider />
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <NumberFormat
                      fullWidth
                      label="Loss of Use/Rent"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('lUse')}
                      onChange={(e) => setFieldValue('lUse', parseFloat(e.target.values.value))}
                    />
                    <NumberFormat
                      fullWidth
                      label="Rebuilding to Code"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('rebuilding')}
                      onChange={(e) => setFieldValue('rebuilding', parseFloat(e.target.values.value))}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <NumberFormat
                      fullWidth
                      label="Loss of Assessments"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('lAss')}
                      onChange={(e) => setFieldValue('lAss', parseFloat(e.target.values.value))}
                    />
                  </Stack>
                  {!noLCoverageTypes.includes(values.policyType) && (
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                      <NumberFormat
                        fullWidth
                        label="Medical Payment"
                        prefix="$"
                        placeholder="$0.00"
                        {...getFieldProps('medPayment')}
                        onChange={(e) => setFieldValue('medPayment', parseFloat(e.target.values.value))}
                      />
                      <NumberFormat
                        fullWidth
                        label="Liability Coverage"
                        prefix="$"
                        placeholder="$0.00"
                        {...getFieldProps('lCoverage')}
                        onChange={(e) => setFieldValue('lCoverage', parseFloat(e.target.values.value))}
                      />
                    </Stack>
                  )}
                  {/* Bottom section */}
                  <Divider />
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Status"
                      {...getFieldProps('status')}
                      onChange={(e) => {
                        setFieldValue('status', e.target.value);
                      }}
                    >
                      {INSURANCE_STATUS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack spacing={2}>
                    <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={policies}
                      ListboxProps={{sx: {maxHeight: '160px'}}}
                      disableCloseOnSelect
                      getOptionLabel={(option) => `Real Estate - ${option.policyNumber} - ${option.nickName}`}
                      value={policies.filter((item) => values.rPolicies.some((pol) => pol.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'rPolicies',
                          value.map((item) => ({id: item.id, type: 'realEstate'}))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            Real Estate - {option.policyNumber} - {option.nickName}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Policies" />}
                    />
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={CLAIMS}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        `${fDate(option.dateOfLoss)} - ${option.policyNumber} - ${capitalCase(option.policyType)} - ${
                          option.nickName
                        }`
                      }
                      value={CLAIMS.filter((item) => values.rClaims.includes(item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'rClaims',
                          value.map((item) => item.id)
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {fDate(option.dateOfLoss)} - {option.policyNumber} - {capitalCase(option.policyType)} -{' '}
                            {option.nickName}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Claims" />}
                    />
                  </Stack>
                
                </Stack>
              )
            )}
          </DialogContent>

          {step === 1 ? (
            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  handleClose();
                  setStep(1);
                }}
                color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={() => setStep(2)}>
                Next
              </LoadingButton>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => setStep(1)} color="inherit">
                Back
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
                Submit
              </LoadingButton>
            </DialogActions>
          )}
        </Dialog>
      </Form>
      {step === 2 && option === IMPORT_OPTIONS.carrier && (
        <DisplayInsuranceList handleReset={() => setStep(1)} handleClose={handleClose} />
      )}
    </FormikProvider>
  );
}

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {omit, entries, values} from 'lodash';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useSelector} from 'src/redux/store';
// components
import REDialog from './components/RealEstate/AddNew';
import CDialog from './components/Collection/AddNew';
import ADialog from './components/Automobile/AddNew';
import WDialog from './components/Watercraft/AddNew';
import AiDialog from './components/Aircraft/AddNew';
import UmDialog from './components/Umbrella/AddNew';
import MiDialog from './components/Misc/AddNew';
import EDialog from './components/Equine/AddNew';
import CLDialog from './components/Claims/AddNew';
// ----------------------------------------------------------------------

PolicyDialog.propTypes = {
  passOptionStep: PropTypes.bool,
  tab: PropTypes.string,
  handleClose: PropTypes.func.isRequired
};

export default function PolicyDialog({tab, passOptionStep = false, handleClose}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  // NOTE: advisor stuff
  const {list, asset: advisorAss, insurance: advisorIns} = useSelector((state) => state.client);
  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show && m.id !== user.userId);

  const clientId = (isAdvisor ? list : members).find((item) => item.show)?.id || '';

  const {isLoading, assets} = useSelector((state) => state.asset);
  const {isLoading: inLoading, insurance} = useSelector((state) => state.insurance);

  let assetsData;
  let insuranceData;

  if (isAdvisor || memberSelected.length === 1) {
    assetsData = Object.entries(advisorAss)
      .map(([key, values]) => ({
        [key]: values.filter((risk) => risk.user === clientId)
      }))
      .reduce((result, item) => ({...result, ...item}), {});

    insuranceData = Object.entries(advisorIns)
      .map(([key, values]) => ({[key]: values.filter((pol) => pol.user === clientId)}))
      .reduce((result, item) => ({...result, ...item}), {});
  } else {
    assetsData = assets;
    insuranceData = insurance;
  }
  const RISKS = useMemo(() => {
    if (!['umbrella', 'claim'].includes(tab)) {
      return (assetsData[tab] || []).map((risk) => ({...risk, type: tab}));
    }

    // show all assets except collection for umbrella tab
    return entries(assetsData).reduce(
      (res, [key, records]) =>
        (key === 'collection' || key === 'general' || key === 'equine') ? res : res?.concat((records || []).map((record) => ({...record, type: key}))),
      []
    );
  }, [tab, assetsData]);

  const policies = useMemo(
    () =>
      values(omit(insuranceData, ['claim','general']))
        .flat()
        .filter((policy) => policy && !policy.shared && policy.status === 'active'),
    [insuranceData]
  );
  const CLAIMS = insuranceData.claim;

  const renderContent = () => {
    if (tab === 'realEstate') {
      return (
        <REDialog
          tab={tab}
          passOptionStep={passOptionStep}
          clientId={clientId}
          isLoading={isLoading}
          inLoading={inLoading}
          RISKS={RISKS}
          CLAIMS={CLAIMS}
          handleClose={handleClose}
          policies={policies.filter((item) => item.type === 'realEstate')}
        />
      );
    } else if (tab === 'collection') {
      return (
        <CDialog
          tab={tab}
          passOptionStep={passOptionStep}
          clientId={clientId}
          isLoading={isLoading}
          inLoading={inLoading}
          RISKS={RISKS}
          CLAIMS={CLAIMS}
          handleClose={handleClose}
          policies={policies.filter((item) => item.type === 'collection')}
        />
      );
    } else if (tab === 'automobile') {
      return (
        <ADialog
          tab={tab}
          passOptionStep={passOptionStep}
          clientId={clientId}
          isLoading={isLoading}
          inLoading={inLoading}
          RISKS={RISKS}
          CLAIMS={CLAIMS}
          handleClose={handleClose}
          policies={policies.filter((item) => item.type === 'automobile')}
        />
      );
    } else if (tab === 'watercraft') {
      return (
        <WDialog
          tab={tab}
          passOptionStep={passOptionStep}
          clientId={clientId}
          isLoading={isLoading}
          inLoading={inLoading}
          RISKS={RISKS}
          CLAIMS={CLAIMS}
          handleClose={handleClose}
          policies={policies.filter((item) => item.type === 'watercraft')}
        />
      );
    } else if (tab === 'aircraft') {
      return (
        <AiDialog
          tab={tab}
          passOptionStep={passOptionStep}
          clientId={clientId}
          isLoading={isLoading}
          inLoading={inLoading}
          RISKS={RISKS}
          CLAIMS={CLAIMS}
          handleClose={handleClose}
          policies={policies.filter((item) => item.type === 'aircraft')}
        />
      );
    } else if (tab === 'umbrella') {
      return (
        <UmDialog
          tab={tab}
          passOptionStep={passOptionStep}
          clientId={clientId}
          isLoading={isLoading}
          inLoading={inLoading}
          RISKS={RISKS}
          CLAIMS={CLAIMS}
          handleClose={handleClose}
          policies={policies.filter((item) => item.type === 'umbrella')}
        />
      );
    } else if (tab === 'misc') {
      return (
        <MiDialog
          tab={tab}
          passOptionStep={passOptionStep}
          clientId={clientId}
          inLoading={inLoading}
          CLAIMS={CLAIMS}
          handleClose={handleClose}
          policies={policies.filter((item) => item.type === 'misc')}
        />
      );
    } else if (tab === 'equine') {
      return (
        <EDialog
          tab={tab}
          passOptionStep={passOptionStep}
          clientId={clientId}
          isLoading={isLoading}
          inLoading={inLoading}
          RISKS={RISKS}
          CLAIMS={CLAIMS}
          handleClose={handleClose}
          policies={policies.filter((item) => item.type === 'equine')}
        />
      );
    } else if (tab === 'claim') {
      return (
        <CLDialog
          tab={tab}
          passOptionStep={passOptionStep}
          clientId={clientId}
          isLoading={isLoading}
          inLoading={inLoading}
          RISKS={RISKS}
          CLAIMS={CLAIMS}
          handleClose={handleClose}
          policies={policies}
        />
      );
    }

    return null;
  };

  return renderContent();
}

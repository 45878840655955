import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSnackbar} from 'notistack';
// mui
import {Box, Card, CardHeader, CardContent, CircularProgress, Grid, Stack, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
// api
import {getRiskScoreData} from 'src/api';
import {getAdvisorRiskScore} from 'src/api/advisor';
// hooks
import useAuth from 'src/hooks/useAuth';
//redux
import {useSelector} from 'src/redux/store';
// constants
import {
  EMPLOYMENT_PRACTICES,
  INIT_RISK,
  LIAB_MGMT,
  LIFE_ACCIDENT,
  PRO_PROTECTION,
  CYBER_RISK
} from 'src/constants/risk-score';
// components
import StatusItem from 'src/components/StatusItem';
import EmptyContent from 'src/components/EmptyContent';
// ----------------------------------------------------------------------

RiskScore.propTypes = {
  data: PropTypes.object
};

const LabelStyle = styled(Typography)(({theme}) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

export default function RiskScore() {
  const {user} = useAuth();
  const {list} = useSelector((state) => state.client);
  const {members} = useSelector((state) => state.familyGroup);
  const {enqueueSnackbar} = useSnackbar();

  const isAdvisor = user.role === 'Advisor';
  const clientId = list.find((item) => item.show)?.id || '';

  const [userData, setUserData] = useState(INIT_RISK);
  const [data, setData] = useState(INIT_RISK);
  const [loading, setLoading] = useState(false);
  const memberSelected = members.filter((m) => m.show);
  const smCounts = memberSelected.length;
  const memberId = smCounts === 1 ? memberSelected[0].id : null; 

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);
      try {
        let userData = {...INIT_RISK};
        let data = {...INIT_RISK};

        if (isAdvisor || smCounts === 1) {
          const res = await getAdvisorRiskScore(user.role !== 'Advisor');
          if (!!res?.data?.length > 0) {
            let target;
            if (user.role === 'Advisor') {
              target = res?.data?.find((item) => item?.userId === clientId);
            } else {
              target = res?.data?.find((item) => item?.userId === memberId);
            }
            if (!!target && isMounted) {
              data = {...target};
            }
          }
        } else {
          userData = await getRiskScoreData();
        }

        if (isMounted) {
          setUserData(userData);
          setData(data);
        }
      } catch (e) {
        if (isMounted) {
          console.warn(e);
          enqueueSnackbar(e.message || 'Get riskscore info error.', {variant: 'error'});
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => (isMounted = false);
  }, [isAdvisor, clientId, memberId, user, smCounts, enqueueSnackbar]);

  const riskScoreData =
    (isAdvisor || smCounts === 1)
      ? data
      : userData;

  const renderContent = () => {
    if (loading)
      return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '160px'}}>
          <CircularProgress />
        </Box>
      );

    if (!riskScoreData) {
      return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '160px'}}>
          <EmptyContent title={`No data found`} />
        </Box>
      );
    }

    return (
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={1} sx={{mb: 4, ml: 1}}>
            <LabelStyle>Liability Management</LabelStyle>
            {LIAB_MGMT.map((item) => (
              <StatusItem key={item.key} flag={riskScoreData[item.key]} label={item.label} />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={1} sx={{mb: 4, ml: 1}}>
            <LabelStyle>Property Protection</LabelStyle>
            {PRO_PROTECTION.map((item) => (
              <StatusItem key={item.key} flag={riskScoreData[item.key]} label={item.label} />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={1} sx={{mb: 4, ml: 1}}>
            <LabelStyle>Cyber Risk Management</LabelStyle>
            {CYBER_RISK.map((item) => (
              <StatusItem key={item.key} flag={riskScoreData[item.key]} label={item.label} />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={1} sx={{mb: 4, ml: 1}}>
            <LabelStyle>Directorships & Employment Practices</LabelStyle>
            {EMPLOYMENT_PRACTICES.map((item) => (
              <StatusItem key={item.key} flag={riskScoreData[item.key]} label={item.label} />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={1} sx={{mb: 4, ml: 1}}>
            <LabelStyle>Life, Accident, Health</LabelStyle>
            {LIFE_ACCIDENT.map((item) => (
              <StatusItem key={item.key} flag={riskScoreData[item.key]} label={item.label} />
            ))}
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card sx={{m: 1, height: 'calc(100% - 24px)'}}>
      <CardHeader title="Risk Score Card" />
      <CardContent>{renderContent()}</CardContent>
    </Card>
  );
}

import React , { useState}from 'react';
import {useNavigate} from 'react-router-dom';
import {capitalCase} from 'change-case';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
import {useSelector} from 'react-redux';
// mui
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
  RadioGroup,
  Radio
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import AddIcon from '@mui/icons-material/Add';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addInsurance} from 'src/redux/slices/insurance';
import {addClientInsurance} from 'src/redux/slices/client';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// utils
import {fDate, fDateZeroTime} from 'src/utils/formatTime';
import {getInsStatusByDate} from 'src/utils/getInsStatusByDate';
// components
import NumberFormat from 'src/components/NumberFormat';
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
import {ContactSelect, FormikErrorNotification, TextFieldWithHover} from 'src/components/_dashboard/common';
import DisplayInsuranceList from 'src/components/canopy/DisplayInsuranceList';
// constants
import {IMPORT_OPTIONS} from 'src/constants/insurance';
import {
  IN_AU_TYPE,
  INSURANCE_STATUS,
  FP_TORT_OPTIONS,
  AU_DEDUCTIBLE_OPTION,
  AU_PAYMENT_OPTION
} from 'src/constants/dropdown';
// ----------------------------------------------------------------------

AddDialog.propTypes = {
  passOptionStep: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  inLoading: PropTypes.bool,
  RISKS: PropTypes.array,
  policies: PropTypes.array,
  CLAIMS: PropTypes.array,
  handleClose: PropTypes.func.isRequired
};

export default function AddDialog({
  passOptionStep,
  clientId,
  tab,
  isLoading = false,
  inLoading = false,
  RISKS = [],
  policies = [],
  CLAIMS = [],
  handleClose
}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show && m.id !== user.userId);

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [step, setStep] = useState(passOptionStep ? 2 : 1);
  const [option, setOption] = useState(IMPORT_OPTIONS.manual);

  const AddSchema = Yup.object().shape({
    policyType: Yup.string().isRequired,
    policyNumber: Yup.string().required('Policy Number is required'),
    policyDocs: Yup.array(),
    cDeductible: Yup.number().required('Comprehensive Deducible should be a valid integer'),
    coDeductible: Yup.number().required('Collision Deductible should be a valid integer'),
    effDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    expDate: Yup.date().typeError('Expiration Date is required.').min(new Date(1900, 0, 1)),
    naic: Yup.string()
    .matches(/^\d{5}$/, 'NAIC must be exactly 5 digits')
    .required('NAIC is required'),
  });

  const formik = useFormik({
    initialValues: {
      policyType: 'priv-pass',
      policyNumber: '',
      nickName: '',
      carrier: '',
      broker: '',
      risks: [],
      primaryContact: [],
      issuingCompany: '',
      naic:'',
      namedInsured: [],
      additionalInsured: [],
      operator: [],
      effDate: null,
      expDate: null,
      premium: '',
      bodyInjuryPerson: '', // Body Injury per person
      bodyInjuryAccident: '', // Body Injury per accident
      proDamage: '', // Property Damage
      mPayment: '', // Medical Payments to Others (from canopy mapping)
      unMotorist: '', // Uninsured Motorist
      underMotorist: '', // Underinsured Motorist
      unMotoristStacking: false, // Uninsured Motorist Stacking
      fptOption: '', // First Part Tort Option
      fpmExpense: '', // First Party Medical Expense
      fpWorkLoss: '', // First Party Work Loss
      fpaDeath: '', // First Party Accidental Death
      fpcBenefit: '', // First Party Combined Benefit
      fpeMedical: '', // First Party Extraordinary Medical
      smCoverage: false, // State Maximum Coverage
      cDeductible: 0, // Comprehensive Deducible
      coDeductible: 0, // Collision Deductible
      pBasis: '', // Payment Basis
      rrDaily: '', // Rental Reimbursement - Daily
      rrMax: '', // Rental Reimbursement - Maximum
      dwTotalLoss: false, // Deductible Waiver for Total Loss
      fullGlass: false, // Full Glass
      rbvIncluded: false, // Rented/Borrowed Vehicles Included
      orFacility: false, // Choose Own Repair Facility
      klReplacement: false, // Key & Lock Replacement
      trAssist: false, // Towing/Roadside Assistance
      trValue: 0, // Towing/Roadside Value (from canopy mapping)
      llGap: false, // Loan/Lease Gap
      rsGap: false, // Rideshare Endorsement (from canopy mapping)
      status: 'active',
      rPolicies: [], // Related Policy IDS
      rClaims: [], // Related Claim IDS
      policyDocs: [],
      note: ''
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: tab};
        const res =
          !!clientId || memberSelected.length === 1
            ? await dispatch(addClientInsurance(payload, clientId, !isAdvisor))
            : await dispatch(addInsurance(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('Add Insurance success', {variant: 'success'});
          const id = res?.data.id || res?.data._id;

          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/insurance/automobile/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/insurance/automobile/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Insurance creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, resetForm, getFieldProps, setFieldValue, setValues, handleSubmit, isSubmitting} =
    formik;

  

  const handleInsStatusByEffecDate = (date) => {
    setValues({
      ...values,
      effDate: fDateZeroTime(date),
      status: getInsStatusByDate(date, values, 'effDate')
    });
  };

  const handleInsStatusByExpDate = (date) => {
    setValues({
      ...values,
      expDate: fDateZeroTime(date),
      status: getInsStatusByDate(date, values, 'expDate')
    });
  };
  const isManual = option === IMPORT_OPTIONS.manual;

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={isManual || step === 1} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>{`Add New Insurance${isManual ? ' (Automobile)' : ''}`}</DialogTitle>
          <Divider />
          <DialogContent>
            {step === 1 ? (
              <RadioGroup name="radio-buttons-group" value={option} onChange={(e) => setOption(e.target.value)}>
                <FormControlLabel value={IMPORT_OPTIONS.manual} control={<Radio />} label="Add Manually" />
                <FormControlLabel value={IMPORT_OPTIONS.carrier} control={<Radio />} label="Import From Carrier" />
              </RadioGroup>
            ) : (
              isManual && (
                <Stack spacing={2}>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Autocomplete
                      loading={isLoading}
                      fullWidth
                      multiple
                      options={RISKS}
                      sx={{width: '70%'}}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.nickName || option.id}
                      value={RISKS.filter((item) => values.risks.some((value) => value.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'risks',
                          value.map((item) => ({id: item.id, type: item.type}))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {option.nickName || option.id}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Risk" placeholder="Select Risk" />}
                    />
                    <Button
                      variant="contained"
                      sx={{borderRadius: '30px'}}
                      onClick={() => {
                        navigate('/dashboard/asset', {state: {view: 'automobile', showModal: true, goBack: true}});
                      }}
                    >
                      <AddIcon />
                      Add New Asset
                    </Button>
                  </Stack>
                  {/* Top section - identify policy, select risk */}
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Policy Type"
                      {...getFieldProps('policyType')}
                      onChange={(e) => setFieldValue('policyType', e.target.value)}
                      error={Boolean(touched.policyType && errors.policyType)}
                      helperText={touched.policyType && errors.policyType}
                    >
                      {IN_AU_TYPE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      {...getFieldProps('policyNumber')}
                      fullWidth
                      type="text"
                      label="Policy Number"
                      error={Boolean(touched.policyNumber && errors.policyNumber)}
                      helperText={touched.policyNumber && errors.policyNumber}
                    />
                    <TextFieldWithHover
                      {...getFieldProps('nickName')}
                      fullWidth
                      type="string"
                      label="Policy Name"
                      info="Input the policy name"
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="primaryContact"
                      label="Primary Contact"
                      values={values.primaryContact}
                      setFieldValue={setFieldValue}
                      isPrimary={true}
                    />
                    <TextField
                      {...getFieldProps('carrier')}
                      fullWidth
                      type="text"
                      label="Insurance Carrier"
                      error={Boolean(touched.carrier && errors.carrier)}
                      helperText={touched.carrier && errors.carrier}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      {...getFieldProps('broker')}
                      fullWidth
                      type="text"
                      label="Brokerage"
                      error={Boolean(touched.broker && errors.broker)}
                      helperText={touched.broker && errors.broker}
                    />
                    <TextField
                      {...getFieldProps('issuingCompany')}
                      fullWidth
                      type="text"
                      label="Issuing Company"
                      error={Boolean(touched.issuingCompany && errors.issuingCompany)}
                      helperText={touched.issuingCompany && errors.issuingCompany}
                    />
                    <TextField
                      {...formik.getFieldProps('naic')}
                      fullWidth
                      type="text"
                      label="NAIC"
                      error={Boolean(formik.touched.naic && formik.errors.naic)}
                      helperText={formik.touched.naic && formik.errors.naic}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2}>
                    <DatePicker
                      fullWidth
                      label="Effective Date"
                      {...getFieldProps('effDate')}
                      onChange={handleInsStatusByEffecDate}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                    <DatePicker
                      fullWidth
                      label="Expiration Date*"
                      {...getFieldProps('expDate')}
                      onChange={handleInsStatusByExpDate}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={Boolean(touched.expDate && errors.expDate)}
                          helperText={touched.expDate && errors.expDate}
                        />
                      )}
                    />
                    <StatusLabel status={values.status} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="namedInsured"
                      label="Named Insured"
                      values={values.namedInsured}
                      setFieldValue={setFieldValue}
                    />
                    <ContactSelect
                      clientId={clientId}
                      keyValue="additionalInsured"
                      label="Additional Insured"
                      values={values.additionalInsured}
                      setFieldValue={setFieldValue}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="operator"
                      label="Operator"
                      values={values.operator}
                      setFieldValue={setFieldValue}
                    />
                    <NumberFormat
                      fullWidth
                      label="Premium"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('premium')}
                      onChange={(e) => setFieldValue('premium', parseFloat(e.target.values.value))}
                    />
                  </Stack>

                  {/* Middle section - coverage limits */}
                  <Divider />
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <Stack sx={{p: 1}} spacing={1}>
                        <Typography variant="h6">Liability</Typography>
                        <NumberFormat
                          fullWidth
                          label="Body Injury(Person)"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('bodyInjuryPerson')}
                          onChange={(e) => setFieldValue('bodyInjuryPerson', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="Body Injury(Accident)"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('bodyInjuryAccident')}
                          onChange={(e) => setFieldValue('bodyInjuryAccident', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="Property Damage"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('proDamage')}
                          onChange={(e) => setFieldValue('proDamage', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="Medical Payments to Others"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('mPayment')}
                          onChange={(e) => setFieldValue('mPayment', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="Uninsured Motorist"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('unMotorist')}
                          onChange={(e) => setFieldValue('unMotorist', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="Underinsured Motorist"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('underMotorist')}
                          onChange={(e) => setFieldValue('underMotorist', parseFloat(e.target.values.value))}
                        />
                        <FormControlLabel
                          control={
                            <Switch {...getFieldProps('unMotoristStacking')} checked={values.unMotoristStacking} />
                          }
                          label="Uninsured Motorist Stacking"
                          labelPlacement="end"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Stack sx={{p: 1}} spacing={1}>
                        <Typography variant="h6">Personal Injury Protection (PIP)</Typography>
                        <TextField
                          select
                          fullWidth
                          label="First Part Tort Option"
                          {...getFieldProps('fptOption')}
                          onChange={(e) => setFieldValue('fptOption', e.target.value)}
                        >
                          {FP_TORT_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <NumberFormat
                          fullWidth
                          label="First Party Medical Expense"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('fpmExpense')}
                          onChange={(e) => setFieldValue('fpmExpense', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="First Party Work Loss"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('fpWorkLoss')}
                          onChange={(e) => setFieldValue('fpWorkLoss', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="First Party Accidental Death"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('fpaDeath')}
                          onChange={(e) => setFieldValue('fpaDeath', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="First Party Combined Benefit"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('fpcBenefit')}
                          onChange={(e) => setFieldValue('fpcBenefit', parseFloat(e.target.values.value))}
                        />
                        <NumberFormat
                          fullWidth
                          label="First Party Extraordinary Medical"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('fpeMedical')}
                          onChange={(e) => setFieldValue('fpeMedical', parseFloat(e.target.values.value))}
                        />
                        <FormControlLabel
                          control={<Switch {...getFieldProps('smCoverage')} checked={values.smCoverage} />}
                          label="State Maximum Coverage"
                          labelPlacement="end"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Stack sx={{p: 1}} spacing={1}>
                        <Typography variant="h6">Property</Typography>
                        <TextField
                          select
                          fullWidth
                          label="Comprehensive Deductible"
                          value={values.cDeductible}
                          {...getFieldProps('cDeductible')}
                          onChange={(e) => setFieldValue('cDeductible', e.target.value)}
                        >
                          {AU_DEDUCTIBLE_OPTION.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          select
                          fullWidth
                          label="Collision Deductible"
                          value={values.coDeductible}
                          {...getFieldProps('coDeductible')}
                          onChange={(e) => setFieldValue('coDeductible', e.target.value)}
                        >
                          {AU_DEDUCTIBLE_OPTION.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          select
                          fullWidth
                          label="Payment Basis"
                          {...getFieldProps('pBasis')}
                          onChange={(e) => setFieldValue('pBasis', e.target.value)}
                        >
                          {AU_PAYMENT_OPTION.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Typography variant="h6">Rental Reimbursement</Typography>
                        <Stack direction="row" spacing={1}>
                          <NumberFormat
                            fullWidth
                            label="Daily"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('rrDaily')}
                            onChange={(e) => setFieldValue('rrDaily', parseFloat(e.target.values.value))}
                          />
                          <NumberFormat
                            fullWidth
                            label="Maximum"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('rrMax')}
                            onChange={(e) => setFieldValue('rrMax', parseFloat(e.target.values.value))}
                          />
                        </Stack>
                        <FormControlLabel
                          control={<Switch {...getFieldProps('dwTotalLoss')} checked={values.dwTotalLoss} />}
                          label="Deductible Waiver for Total Loss"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={<Switch {...getFieldProps('fullGlass')} checked={values.fullGlass} />}
                          label="Full Glass"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={<Switch {...getFieldProps('rbvIncluded')} checked={values.rbvIncluded} />}
                          label="Rented/Borrowed Vehicles Included"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={<Switch {...getFieldProps('orFacility')} checked={values.orFacility} />}
                          label="Choose Own Repair Facility"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={<Switch {...getFieldProps('klReplacement')} checked={values.klReplacement} />}
                          label="Key & Lock Replacement"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={<Switch {...getFieldProps('trAssist')} checked={values.trAssist} />}
                          label="Towing/Roadside Assistance"
                          labelPlacement="end"
                        />
                        <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
                          <NumberFormat
                            fullWidth
                            label=""
                            prefix="$"
                            placeholder="Fair Cost"
                            {...getFieldProps('trValue')}
                            onChange={(e) => setFieldValue('trValue', parseInt(e.target.values.value, 10))}
                          />
                        </Stack>
                        <FormControlLabel
                          control={<Switch {...getFieldProps('llGap')} checked={values.llGap} />}
                          label="Loan/Lease Gap"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={<Switch {...getFieldProps('rsGap')} checked={values.rsGap} />}
                          label="Rideshare Endorsement"
                          labelPlacement="end"
                        />
                      </Stack>
                    </Grid>
                  </Grid>

                  {/* Bottom section */}
                  <Divider />
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Status"
                      {...getFieldProps('status')}
                      onChange={(e) => setFieldValue('status', e.target.value)}
                    >
                      {INSURANCE_STATUS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack spacing={2}>
                    <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={policies}
                      ListboxProps={{sx: {maxHeight: '160px'}}}
                      disableCloseOnSelect
                      getOptionLabel={(option) => `Automobile - ${option.policyNumber} - ${option.nickName}`}
                      value={policies.filter((item) => values.rPolicies.some((pol) => pol.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'rPolicies',
                          value.map((item) => ({id: item.id, type: 'automobile'}))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            Automobile - {option.policyNumber} - {option.nickName}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Policies" />}
                    />
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={CLAIMS}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        `${fDate(option.dateOfLoss)} - ${option.policyNumber} - ${capitalCase(option.policyType)} - ${
                          option.nickName
                        }`
                      }
                      value={CLAIMS.filter((item) => values.rClaims.includes(item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'rClaims',
                          value.map((item) => item.id)
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {fDate(option.dateOfLoss)} - {option.policyNumber} - {capitalCase(option.policyType)} -{' '}
                            {option.nickName}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Claims" />}
                    />
                  </Stack>
                  
                </Stack>
              )
            )}
          </DialogContent>
          {step === 1 ? (
            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  handleClose();
                  setStep(1);
                }}
                color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={() => setStep(2)}>
                Next
              </LoadingButton>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => setStep(1)} color="inherit">
                Back
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
                Submit
              </LoadingButton>
            </DialogActions>
          )}
        </Dialog>
      </Form>
      {step === 2 && option === IMPORT_OPTIONS.carrier && (
        <DisplayInsuranceList handleReset={() => setStep(1)} handleClose={handleClose} />
      )}
    </FormikProvider>
  );
}

import React, { useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {Form, FormikProvider, useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
// mui
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Stack,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {addInsurance} from 'src/redux/slices/insurance';
import {useDispatch} from 'src/redux/store';
import {addClientInsurance} from 'src/redux/slices/client';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
import {fDateZeroTime} from 'src/utils/formatTime';
// components
import NumberFormat from 'src/components/NumberFormat';
import DisplayInsuranceList from 'src/components/canopy/DisplayInsuranceList';
import {ContactSelect, FormikErrorNotification, TextFieldWithHover} from 'src/components/_dashboard/common';
// constants
import {IMPORT_OPTIONS} from 'src/constants/insurance';
import {INSURANCE_STATUS} from 'src/constants/dropdown';
// ----------------------------------------------------------------------

AddDialog.propTypes = {
  passOptionStep: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  inLoading: PropTypes.bool,
  policies: PropTypes.array,
  CLAIMS: PropTypes.array,
  handleClose: PropTypes.func.isRequired
};

export default function AddDialog({
  passOptionStep,
  clientId,
  tab,
  inLoading = false,
  policies = [],
  CLAIMS = [],
  handleClose
}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show && m.id !== user.userId);

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [step, setStep] = useState(passOptionStep ? 2 : 1);
  const [option, setOption] = useState(IMPORT_OPTIONS.manual);

  const AddSchema = Yup.object().shape({
    policyType: Yup.string().isRequired,
    policyNumber: Yup.string().required('Policy Number is required'),
    policyDocs: Yup.array(),
    travelInsuranceEffectiveDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    travelInsuranceExpirationDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    travelInsuranceDateIssued: Yup.date().nullable().min(new Date(1900, 0, 1)),
    healthInsuranceEffectiveDate: Yup.date().nullable().min(new Date(1900, 0, 1))
  });

  const formik = useFormik({
    initialValues: {
      policyType: 'travel',
      policyNumber: '',
      nickName: '',

      //policyType === travel
      travelInsuranceType: 'annual',
      travelInsuranceBroker: '',
      travelInsuranceEffectiveDate: null,
      travelInsuranceExpirationDate: null,
      travelInsurancePremium: 0,
      travelInsuranceInsurer: '',
      travelInsuranceNamedInsured: [],
      travelInsuranceAdditionalInsured: [],
      travelInsurancePolicyUpload: [],

      //policyType === life
      lifeInsuranceType: 'permanent',
      lifeInsuranceTermLength: 0,
      lifeInsuranceTermDateIssued: null,

      //policyType === health
      healthInsuranceEffectiveDate: null,
      healthInsuranceAnnualPremium: 0,

      status: 'active',
      relatedPolicies: [], // Related Policy IDS
      relatedClaims: [], // Related Claim IDS
      policyDocs: [],
      note: ''
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: tab};
        const res =
          !!clientId || memberSelected.length === 1
            ? await dispatch(addClientInsurance(payload, clientId, !isAdvisor))
            : await dispatch(addInsurance(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('Add Insurance success', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/insurance/misc/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/insurance/misc/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Insurance creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, resetForm, getFieldProps, setFieldValue, handleSubmit, isSubmitting} = formik;

  

  const isManual = option === IMPORT_OPTIONS.manual;

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={isManual || step === 1} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>{`Add New Insurance${isManual ? ' (Misc)' : ''}`}</DialogTitle>
          <Divider />
          <DialogContent>
            {step === 1 ? (
              <RadioGroup name="radio-buttons-group" value={option} onChange={(e) => setOption(e.target.value)}>
                <FormControlLabel value={IMPORT_OPTIONS.manual} control={<Radio />} label="Add Manually" />
                <FormControlLabel value={IMPORT_OPTIONS.carrier} control={<Radio />} label="Import From Carrier" />
              </RadioGroup>
            ) : (
              isManual && (
                <Stack spacing={2}>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Policy Type"
                      {...getFieldProps('policyType')}
                      onChange={(e) => setFieldValue('policyType', e.target.value)}
                    >
                      <MenuItem value="travel">Travel</MenuItem>
                      <MenuItem value="life">Life</MenuItem>
                      <MenuItem value="health">Health</MenuItem>
                    </TextField>
                    <TextField
                      {...getFieldProps('policyNumber')}
                      fullWidth
                      type="text"
                      label="Policy Number"
                      error={Boolean(touched.policyNumber && errors.policyNumber)}
                      helperText={touched.policyNumber && errors.policyNumber}
                    />
                    <TextFieldWithHover
                      {...getFieldProps('nickName')}
                      fullWidth
                      type="string"
                      label="Policy Name"
                      info="Input the policy name"
                    />
                  </Stack>
                  {values.policyType === 'travel' && (
                    <>
                      <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                        <TextField
                          select
                          fullWidth
                          label="Travel Insurance Type"
                          {...getFieldProps('travelInsuranceType')}
                          onChange={(e) => setFieldValue('travelInsuranceType', e.target.value)}
                        >
                          <MenuItem value="annual">Annual</MenuItem>
                          <MenuItem value="trip">Trip</MenuItem>
                        </TextField>
                        <TextField
                          {...getFieldProps('travelInsuranceBroker')}
                          fullWidth
                          type="text"
                          label="Brokerage"
                          error={Boolean(touched.travelInsuranceBroker && errors.travelInsuranceBroker)}
                          helperText={touched.travelInsuranceBroker && errors.travelInsuranceBroker}
                        />
                      </Stack>
                      <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2}>
                        <DatePicker
                          fullWidth
                          label="Effective Date"
                          {...getFieldProps('travelInsuranceEffectiveDate')}
                          onChange={(date) => {
                            setFieldValue('travelInsuranceEffectiveDate', fDateZeroTime(date));
                          }}
                          inputFormat="MM/dd/yyyy"
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                        <DatePicker
                          fullWidth
                          label="Expiration Date"
                          {...getFieldProps('travelInsuranceExpirationDate')}
                          onChange={(date) => {
                            setFieldValue('travelInsuranceExpirationDate', fDateZeroTime(date));
                          }}
                          inputFormat="MM/dd/yyyy"
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                      </Stack>
                      <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                        <NumberFormat
                          fullWidth
                          label="Premium"
                          prefix="$"
                          placeholder="$0.00"
                          {...getFieldProps('travelInsurancePremium')}
                          onChange={(e) => setFieldValue('travelInsurancePremium', parseFloat(e.target.values.value))}
                        />
                        <TextField
                          {...getFieldProps('travelInsuranceInsurer')}
                          fullWidth
                          type="text"
                          label="Insurer"
                          error={Boolean(touched.travelInsuranceInsurer && errors.travelInsuranceInsurer)}
                          helperText={touched.travelInsuranceInsurer && errors.travelInsuranceInsurer}
                        />
                      </Stack>
                      <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                        <ContactSelect
                          clientId={clientId}
                          keyValue="travelInsuranceNamedInsured"
                          label="Named Insured"
                          values={values.travelInsuranceNamedInsured}
                          setFieldValue={setFieldValue}
                        />
                        <ContactSelect
                          clientId={clientId}
                          keyValue="travelInsuranceAdditionalInsured"
                          label="Additional Insured"
                          values={values.travelInsuranceAdditionalInsured}
                          setFieldValue={setFieldValue}
                        />
                      </Stack>
                    </>
                  )}
                  {values.policyType === 'life' && (
                    <>
                      <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                        <TextField
                          select
                          fullWidth
                          label="Life Insurance Type"
                          {...getFieldProps('lifeInsuranceType')}
                          onChange={(e) => setFieldValue('lifeInsuranceType', e.target.value)}
                        >
                          <MenuItem value="permanent">Permanent</MenuItem>
                          <MenuItem value="term">Term</MenuItem>
                        </TextField>
                      </Stack>
                      <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2}>
                        {values.lifeInsuranceType === 'term' && (
                          <NumberFormat
                            fullWidth
                            label="Term Length (years)"
                            placeholder="0"
                            {...getFieldProps('lifeInsuranceTermLength')}
                            onChange={(e) =>
                              setFieldValue('lifeInsuranceTermLength', parseFloat(e.target.values.value))
                            }
                          />
                        )}
                        <DatePicker
                          fullWidth
                          label="Issue Date"
                          {...getFieldProps('travelInsuranceDateIssued')}
                          onChange={(date) => {
                            setFieldValue('travelInsuranceDateIssued', fDateZeroTime(date));
                          }}
                          inputFormat="MM/dd/yyyy"
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                      </Stack>
                    </>
                  )}
                  {values.policyType === 'health' && (
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                      <DatePicker
                        fullWidth
                        label="Effective Date"
                        {...getFieldProps('healthInsuranceEffectiveDate')}
                        onChange={(date) => {
                          setFieldValue('healthInsuranceEffectiveDate', fDateZeroTime(date));
                        }}
                        inputFormat="MM/dd/yyyy"
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                      <NumberFormat
                        fullWidth
                        label="Annual Premium"
                        prefix="$"
                        placeholder="$0.00"
                        {...getFieldProps('healthInsuranceAnnualPremium')}
                        onChange={(e) =>
                          setFieldValue('healthInsuranceAnnualPremium', parseFloat(e.target.values.value))
                        }
                      />
                    </Stack>
                  )}
                  <Divider />
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Status"
                      {...getFieldProps('status')}
                      onChange={(e) => setFieldValue('status', e.target.value)}
                    >
                      {INSURANCE_STATUS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack spacing={2}>
                    <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={policies}
                      disableCloseOnSelect
                      getOptionLabel={(option) => `Misc - ${option.policyNumber}`}
                      value={policies.filter((item) => values.relatedPolicies.some((pol) => pol.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'relatedPolicies',
                          value.map((item) => ({id: item.id, type: 'misc'}))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            Misc - {option.policyNumber}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Policies" />}
                    />
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={CLAIMS}
                      disableCloseOnSelect
                      getOptionLabel={(option) => `${option.nickName}(${option.id})`}
                      value={CLAIMS.filter((item) => values.relatedClaims.includes(item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'relatedClaims',
                          value.map((item) => item.id)
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {`${option.nickName}(${option.id})`}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Claims" />}
                    />
                  </Stack>
                  
                </Stack>
              )
            )}
          </DialogContent>
          {step === 1 ? (
            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  handleClose();
                  setStep(1);
                }}
                color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={() => setStep(2)}>
                Next
              </LoadingButton>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => setStep(1)} color="inherit">
                Back
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
                Submit
              </LoadingButton>
            </DialogActions>
          )}
        </Dialog>
      </Form>
      {step === 2 && option === IMPORT_OPTIONS.carrier && (
        <DisplayInsuranceList handleReset={() => setStep(1)} handleClose={handleClose} />
      )}
    </FormikProvider>
  );
}

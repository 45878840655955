// mui
import {Stack} from '@mui/material';
// components
import FileItem from 'src/components/FileItem';
import EmptyContent from './EmptyContent';

// ----------------------------------------------------------------------

export default function Attachments({files, small = false, ...other}) {
  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return files && !!files.length ? (
    <Stack direction="row" spacing={1}>
      {files.map(
        (file, index) =>
          file.documentLink && (
            <FileItem
              key={index}
              fileName={file.documentTitle}
              fileUrl={file.documentLink}
              onClick={() => handleClick(file.documentLink)}
            />
          )
      )}
    </Stack>
  ) : small ? null : (
    <EmptyContent />
  );
}

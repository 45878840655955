// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about',
  confirm:'/confirm',
  contact: '/contact',
  privacy: '/privacy-policies',
  terms: '/terms-conditions',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
  
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/app'),
  search: path(ROOTS_DASHBOARD, '/search'),
  proposals: path(ROOTS_DASHBOARD, '/proposals'),
  contacts: {
    root: path(ROOTS_DASHBOARD, '/contacts'),
    detail: path(ROOTS_DASHBOARD, '/contact/:id'),
    editContact: path(ROOTS_DASHBOARD, '/contact/:id/edit')
  },
  assets: {
    root: path(ROOTS_DASHBOARD, '/asset'),
    detail: path(ROOTS_DASHBOARD, '/asset/:type/:id'),
    editAsset: path(ROOTS_DASHBOARD, '/asset/:type/:id/edit')
  },
  insurance: {
    root: path(ROOTS_DASHBOARD, '/insurance'),
    detail: path(ROOTS_DASHBOARD, '/insurance/:type/:id'),
    editAsset: path(ROOTS_DASHBOARD, '/insurance/:type/:id/edit')
  },
  org: {
    root: path(ROOTS_DASHBOARD, '/org'),
    detail: path(ROOTS_DASHBOARD, '/org/:type/:id'),
    editAsset: path(ROOTS_DASHBOARD, '/org/:type/:id/edit')
  },
  fileVault: path(ROOTS_DASHBOARD, '/file-vault'),
  ledger: path(ROOTS_DASHBOARD, '/ledger'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  user: path(ROOTS_DASHBOARD, '/user'),
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:id'),
    editPost: path(ROOTS_DASHBOARD, '/blog/post/:id/edit'),
    newPost: path(ROOTS_DASHBOARD, '/blog/post/new-post')
  }
};

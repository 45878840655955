import React, { useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {capitalCase} from 'change-case';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
import {useSelector} from 'react-redux';
// mui
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import AddIcon from '@mui/icons-material/Add';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addInsurance} from 'src/redux/slices/insurance';
import {addClientInsurance} from 'src/redux/slices/client';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// utils
import {fDate, fDateZeroTime} from 'src/utils/formatTime';
import {getInsStatusByDate} from 'src/utils/getInsStatusByDate';
// components
import NumberFormat from 'src/components/NumberFormat';
import DisplayInsuranceList from 'src/components/canopy/DisplayInsuranceList';
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
import {ContactSelect, FormikErrorNotification, TextFieldWithHover} from 'src/components/_dashboard/common';
// constants
import {IMPORT_OPTIONS} from 'src/constants/insurance';
import {IN_WA_TYPE, INSURANCE_STATUS} from 'src/constants/dropdown';
// ----------------------------------------------------------------------

AddDialog.propTypes = {
  passOptionStep: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  inLoading: PropTypes.bool,
  RISKS: PropTypes.array,
  policies: PropTypes.array,
  CLAIMS: PropTypes.array,
  handleClose: PropTypes.func.isRequired
};

export default function AddDialog({
  passOptionStep,
  clientId,
  tab,
  isLoading = false,
  inLoading = false,
  RISKS = [],
  policies = [],
  CLAIMS = [],
  handleClose
}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show && m.id !== user.userId);

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [step, setStep] = useState(passOptionStep ? 2 : 1);
  const [option, setOption] = useState(IMPORT_OPTIONS.manual);

  const AddSchema = Yup.object().shape({
    policyType: Yup.string().isRequired,
    policyNumber: Yup.string().required('Policy Number is required'),
    policyDocs: Yup.array(),
    effDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    expDate: Yup.date().typeError('Expiration Date is required.').min(new Date(1900, 0, 1))
  });

  const formik = useFormik({
    initialValues: {
      policyType: '',
      policyNumber: '',
      nickName: '',
      carrier: '',
      broker: '',
      primaryContact: [],
      issuingCompany: '',
      risks: [],
      namedInsured: [],
      additionalInsured: [],
      effDate: null,
      expDate: null,
      premium: '',
      operator: [],
      proIndemity: '', // Protection & Indemnity (Liability)
      medPayment: '', // Medical Payments
      unBoarter: '', // Uninsured Boaters
      marEnvDamage: '', // Marine Environmental Damage
      hullCoverage: '', // Hull Coverage
      hullDeductible: '', // Hull Deductible
      huDeductible: '', // Hurricane Deductible
      emergency: '', // Emergency / Haul Out Coverage
      pProperty: '',
      seRescue: '',
      teners: '',
      trailers: '',
      status: 'active',
      rPolicies: [], // Related Policy IDS
      rClaims: [], // Related Claim IDS
      policyDocs: [],
      note: ''
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: tab};
        const res =
          !!clientId || memberSelected.length === 1
            ? await dispatch(addClientInsurance(payload, clientId, !isAdvisor))
            : await dispatch(addInsurance(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('Add Insurance success', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/insurance/watercraft/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/insurance/watercraft/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Insurance creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, resetForm, getFieldProps, setFieldValue, setValues, handleSubmit, isSubmitting} =
    formik;

  

  const handleInsStatusByEffecDate = (date) => {
    setValues({
      ...values,
      effDate: fDateZeroTime(date),
      status: getInsStatusByDate(date, values, 'effDate')
    });
  };

  const handleInsStatusByExpDate = (date) => {
    setValues({
      ...values,
      expDate: fDateZeroTime(date),
      status: getInsStatusByDate(date, values, 'expDate')
    });
  };

  const isManual = option === IMPORT_OPTIONS.manual;

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={isManual || step === 1} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>{`Add New Insurance${isManual ? ' (Watercraft)' : ''}`}</DialogTitle>
          <Divider />
          <DialogContent>
            {step === 1 ? (
              <RadioGroup name="radio-buttons-group" value={option} onChange={(e) => setOption(e.target.value)}>
                <FormControlLabel value={IMPORT_OPTIONS.manual} control={<Radio />} label="Add Manually" />
                <FormControlLabel value={IMPORT_OPTIONS.carrier} control={<Radio />} label="Import From Carrier" />
              </RadioGroup>
            ) : (
              isManual && (
                <Stack spacing={2}>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Autocomplete
                      loading={isLoading}
                      fullWidth
                      multiple
                      options={RISKS}
                      sx={{width: '70%'}}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.nickName || option.id}
                      value={RISKS.filter((item) => values.risks.some((value) => value.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'risks',
                          value.map((item) => ({id: item.id, type: item.type}))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {option.nickName || option.id}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Risk" placeholder="Select Risk" />}
                    />
                    <Button
                      variant="contained"
                      sx={{borderRadius: '30px'}}
                      onClick={() => {
                        navigate('/dashboard/asset', {state: {view: 'watercraft', showModal: true, goBack: true}});
                      }}
                    >
                      <AddIcon />
                      Add New Asset
                    </Button>
                  </Stack>
                  {/* Top section - identify policy, select risk */}
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Policy Type"
                      {...getFieldProps('policyType')}
                      onChange={(e) => setFieldValue('policyType', e.target.value)}
                      error={Boolean(touched.policyType && errors.policyType)}
                      helperText={touched.policyType && errors.policyType}
                    >
                      {IN_WA_TYPE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      {...getFieldProps('policyNumber')}
                      fullWidth
                      type="text"
                      label="Policy Number"
                      error={Boolean(touched.policyNumber && errors.policyNumber)}
                      helperText={touched.policyNumber && errors.policyNumber}
                    />
                    <TextFieldWithHover
                      {...getFieldProps('nickName')}
                      fullWidth
                      type="string"
                      label="Policy Name"
                      info="Input the policy name"
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="primaryContact"
                      label="Primary Contact"
                      values={values.primaryContact}
                      setFieldValue={setFieldValue}
                      isPrimary={true}
                    />
                    <TextField
                      {...getFieldProps('carrier')}
                      fullWidth
                      type="text"
                      label="Insurance Carrier"
                      error={Boolean(touched.carrier && errors.carrier)}
                      helperText={touched.carrier && errors.carrier}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      {...getFieldProps('broker')}
                      fullWidth
                      type="text"
                      label="Brokerage"
                      error={Boolean(touched.broker && errors.broker)}
                      helperText={touched.broker && errors.broker}
                    />
                    <TextField
                      {...getFieldProps('issuingCompany')}
                      fullWidth
                      type="text"
                      label="Issuing Company"
                      error={Boolean(touched.issuingCompany && errors.issuingCompany)}
                      helperText={touched.issuingCompany && errors.issuingCompany}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2}>
                    <DatePicker
                      fullWidth
                      label="Effective Date"
                      {...getFieldProps('effDate')}
                      onChange={handleInsStatusByEffecDate}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                    <DatePicker
                      fullWidth
                      label="Expiration Date*"
                      {...getFieldProps('expDate')}
                      onChange={handleInsStatusByExpDate}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={Boolean(touched.expDate && errors.expDate)}
                          helperText={touched.expDate && errors.expDate}
                        />
                      )}
                    />
                    <StatusLabel status={values.status} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="namedInsured"
                      label="Named Insured"
                      values={values.namedInsured}
                      setFieldValue={setFieldValue}
                    />
                    <ContactSelect
                      clientId={clientId}
                      keyValue="additionalInsured"
                      label="Additional Insured"
                      values={values.additionalInsured}
                      setFieldValue={setFieldValue}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <NumberFormat
                      fullWidth
                      label="Premium"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('premium')}
                      onChange={(e) => setFieldValue('premium', parseFloat(e.target.values.value))}
                    />
                    <ContactSelect
                      clientId={clientId}
                      keyValue="operator"
                      label="Operator"
                      values={values.operator}
                      setFieldValue={setFieldValue}
                    />
                  </Stack>

                  {/* Middle section - coverage limits */}
                  <Divider />

                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <Stack sx={{p: 1}} spacing={1}>
                          <Typography variant="h6">Liability</Typography>
                          <NumberFormat
                            fullWidth
                            label="Protection & Indemnity (Liability)"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('proIndemity')}
                            onChange={(e) => setFieldValue('proIndemity', parseFloat(e.target.values.value))}
                          />
                          <NumberFormat
                            fullWidth
                            label="Medical Payments"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('medPayment')}
                            onChange={(e) => setFieldValue('medPayment', parseFloat(e.target.values.value))}
                          />
                          <NumberFormat
                            fullWidth
                            label="Uninsured Boaters"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('unBoarter')}
                            onChange={(e) => setFieldValue('unBoarter', parseFloat(e.target.values.value))}
                          />
                          <NumberFormat
                            fullWidth
                            label="Marine Environmental Damage"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('marEnvDamage')}
                            onChange={(e) => setFieldValue('marEnvDamage', parseFloat(e.target.values.value))}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack sx={{p: 1}} spacing={1}>
                          <Typography variant="h6">Property</Typography>
                          <NumberFormat
                            fullWidth
                            label="Hull Coverage"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('hullCoverage')}
                            onChange={(e) => setFieldValue('hullCoverage', parseFloat(e.target.values.value))}
                          />
                          <NumberFormat
                            fullWidth
                            label="Hull Deductible"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('hullDeductible')}
                            onChange={(e) => setFieldValue('hullDeductible', parseFloat(e.target.values.value))}
                          />
                          <NumberFormat
                            fullWidth
                            label="Hurricane Deductible"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('huDeductible')}
                            onChange={(e) => setFieldValue('huDeductible', parseFloat(e.target.values.value))}
                          />
                          <NumberFormat
                            fullWidth
                            label="Emergency / Haul Out Coverage"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('emergency')}
                            onChange={(e) => setFieldValue('emergency', parseFloat(e.target.values.value))}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack sx={{p: 1}} spacing={1}>
                          <Typography variant="h6">Additional Coverage</Typography>
                          <NumberFormat
                            fullWidth
                            label="Personal Property"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('pProperty')}
                            onChange={(e) => setFieldValue('pProperty', parseFloat(e.target.values.value))}
                          />
                          <NumberFormat
                            fullWidth
                            label="Tenders & Personal Watercraft"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('teners')}
                            onChange={(e) => setFieldValue('teners', parseFloat(e.target.values.value))}
                          />
                          <NumberFormat
                            fullWidth
                            label="Trailers"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('trailers')}
                            onChange={(e) => setFieldValue('trailers', parseFloat(e.target.values.value))}
                          />
                          <NumberFormat
                            fullWidth
                            label="Search & Rescue"
                            prefix="$"
                            placeholder="$0.00"
                            {...getFieldProps('seRescue')}
                            onChange={(e) => setFieldValue('seRescue', parseFloat(e.target.values.value))}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Status"
                      {...getFieldProps('status')}
                      onChange={(e) => setFieldValue('status', e.target.value)}
                    >
                      {INSURANCE_STATUS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>

                  {/* Bottom section */}
                  <Divider />
                  <Stack spacing={2}>
                    <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={policies}
                      ListboxProps={{sx: {maxHeight: '160px'}}}
                      disableCloseOnSelect
                      getOptionLabel={(option) => `Watercraft - ${option.policyNumber} - ${option.nickName}`}
                      value={policies.filter((item) => values.rPolicies.some((pol) => pol.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'rPolicies',
                          value.map((item) => ({id: item.id, type: 'watercraft'}))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            Watercraft - {option.policyNumber} - {option.nickName}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Policies" />}
                    />
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={CLAIMS}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        `${fDate(option.dateOfLoss)} - ${option.policyNumber} - ${capitalCase(option.policyType)} - ${
                          option.nickName
                        }`
                      }
                      value={CLAIMS.filter((item) => values.rClaims.includes(item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'rClaims',
                          value.map((item) => item.id)
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {fDate(option.dateOfLoss)} - {option.policyNumber} - {capitalCase(option.policyType)} -{' '}
                            {option.nickName}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Claims" />}
                    />
                  </Stack>
                 
                </Stack>
              )
            )}
          </DialogContent>
          {step === 1 ? (
            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  handleClose();
                  setStep(1);
                }}
                color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={() => setStep(2)}>
                Next
              </LoadingButton>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => setStep(1)} color="inherit">
                Back
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
                Submit
              </LoadingButton>
            </DialogActions>
          )}
        </Dialog>
      </Form>
      {step === 2 && option === IMPORT_OPTIONS.carrier && (
        <DisplayInsuranceList handleReset={() => setStep(1)} handleClose={handleClose} />
      )}
    </FormikProvider>
  );
}

import React from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
// mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addOrg} from 'src/redux/slices/organization';
import {addClientOrg} from 'src/redux/slices/client';
// utils
import {formatPhoneNumber, getCleanPhoneNumber} from 'src/utils/stringFormat';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import NumberFormat from 'src/components/NumberFormat';
// constants
import {CL_TYPE, ORGANIZATION_STATUS} from 'src/constants/dropdown';
import {AddressFields} from 'src/components/_dashboard/common';
// ----------------------------------------------------------------------

ClubDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default function ClubDialog({handleClose, clientId, open}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const CDSchema = Yup.object().shape({
    name: Yup.string(),
    type: Yup.string(),
    byLaws: Yup.array()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      nickName: '',
      type: '',
      addressId: '',
      primaryPhone: '',
      website: '',
      expense: '',
      due: '',
      status: 'active',
      bondValue: '',
      note: '',
      byLaws: [],
      misc: []
    },
    validationSchema: CDSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: 'club'};
        const res = !!clientId
          ? await dispatch(addClientOrg(payload, clientId, !isAdvisor))
          : await dispatch(addOrg(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('New Org has been created.', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/org/club/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/org/club/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Org creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, getFieldProps, setFieldValue, handleSubmit, isSubmitting} = formik;

  

  return (
    <FormikProvider value={formik}>
      <Form>
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Add New Club</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('name')}
                  fullWidth
                  type="text"
                  label="Name"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField {...getFieldProps('nickName')} fullWidth type="text" label="Organization Name" />
              </Stack>
              <AddressFields
                isRealEstate={false}
                addId={values.addressId}
                handleChange={(id) => {
                  setFieldValue('addressId', id);
                }}
                inputProps={{
                  error: Boolean(touched.addressId && errors.addressId),
                  helperText: touched.addressId && errors.addressId
                }}
              />
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  type="text"
                  label="Primary Phone"
                  value={formatPhoneNumber(values.primaryPhone)}
                  onChange={(e) => setFieldValue('primaryPhone', getCleanPhoneNumber(e.target.value))}
                  error={Boolean(touched.primaryPhone && errors.primaryPhone)}
                  helperText={touched.primaryPhone && errors.primaryPhone}
                />
                <NumberFormat
                  fullWidth
                  label="Expense"
                  prefix="$"
                  {...getFieldProps('expense')}
                  onChange={(e) => setFieldValue('expense', parseFloat(e.target.values.value))}
                />
                <NumberFormat
                  fullWidth
                  label="Club Dues"
                  prefix="$"
                  {...getFieldProps('due')}
                  onChange={(e) => setFieldValue('due', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Organization Status"
                  {...getFieldProps('status')}
                  onChange={(e) => setFieldValue('status', e.target.value)}
                >
                  {ORGANIZATION_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <NumberFormat
                  fullWidth
                  label="Bond Value"
                  prefix="$"
                  placeholder="$0"
                  {...getFieldProps('bondValue')}
                  onChange={(e) => setFieldValue('bondValue', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('website')}
                  fullWidth
                  type="text"
                  label="Website"
                  error={Boolean(touched.website && errors.website)}
                  helperText={touched.website && errors.website}
                />
                <TextField
                  select
                  fullWidth
                  label="Type"
                  {...getFieldProps('clubType')}
                  onChange={(e) => setFieldValue('clubType', e.target.value)}
                >
                  {CL_TYPE.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack spacing={2}>
                <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
              </Stack>
             
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}

import axios from 'axios';
import {baseURL} from 'src/config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;

      // // Add headers for Zillow API requests
      // if (request.url.includes(process.env.REACT_APP_ZILLOW_HOST)) {
      //   request.headers['X-RapidAPI-Key'] = process.env.REACT_APP_ZILLOW_API;
      //   request.headers['X-RapidAPI-Host'] = process.env.REACT_APP_ZILLOW_HOST;
      // }

      // // Add header for HazardHub Api requests
      // if (request.url.includes(process.env.REACT_APP_HAZARDHUB_HOST)) {
      //   request.headers['Authorization'] = `Token token=${process.env.REACT_APP_HAZARDHUB_API}`;
      // }
      return request;
    } else {
      return request;
    }
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
// mui
import {Box, CircularProgress, Typography} from '@mui/material';
// utils
import {fCurrency} from 'src/utils/formatNumber';
import {showOnlyActiveLabels} from 'src/utils/showOnlyActiveLabels';
// components
import BaseOptionChart from './BaseOptionChart';
import {isNullOrEmpty} from 'src/utils/checkEmpty';
// ----------------------------------------------------------------------

const CHART_DATA = {
  series_1: 10,
  series_2: 10
};

const allLabels = [
  {label: 'Total Retirement', key: 'totalRetirement'},
  {label: 'Cash', key: 'cash'},
  {label: 'Net Assets', key: 'netAssets'},
  {label: 'Series-1', key: 'series_1'},
  {label: 'Series-2', key: 'series_2'}
];
export default function TotalNetWorthChart({data, loading}) {
  const isDataEmpty = isNullOrEmpty(data);
  const inputData = isDataEmpty ? CHART_DATA : data;
  const filteredLabels = allLabels.map((item) => item.label);
  const series = allLabels.map((item) => inputData[item.key]);
  const chartData = showOnlyActiveLabels(filteredLabels, series);

  const chartOptions = merge(BaseOptionChart({fCurrencyTooltip: true}), {
    labels: chartData.customLabelsChart,
    stroke: {show: false},
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          ...(isDataEmpty && {
            labels: {
              show: false,
              total: false
            }
          })
        }
      }
    }
  });

  if (!isDataEmpty && data?.totalNetWorthOverride) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <Typography variant="h3">{fCurrency(data?.totalNetWorthOverride)}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{flex: 1, zIndex: 10}}>
      {loading ? (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '260px'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            ...(isDataEmpty && {filter: 'grayscale(90%)', opacity: 0.2})
          }}
        >
          <ReactApexChart type="donut" series={chartData.customDataChart} options={chartOptions} />
        </Box>
      )}
    </Box>
  );
}

// hooks
import {useSelector} from 'react-redux';
import useAuth from 'src/hooks/useAuth';
const {useState, useEffect} = require('react');
// api
const {getAddress} = require('src/api');
// utils
const {getFullAddress} = require('src/utils/stringFormat');
// ----------------------------------------------------------------------

const useAddress = (addressId, userId) => {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const [address, setAddress] = useState({});
  const [error, setError] = useState(null);

  const {members} = useSelector((state) => state.familyGroup);
  const msCounts = members.filter((m) => m.show).length;

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const addressData = await getAddress(addressId, isAdvisor, userId, msCounts >= 1);
        // if no address is returned
        const hasAddress = !!addressData?.country;
        if (!hasAddress) {
          setError(addressData?.message ?? 'Invalid address ID or deleted address.');
          setAddress({failed: true});
          return;
        }

        setAddress(addressData);
      } catch (err) {
        setAddress({failed: true});
        setError(err?.message ?? 'Getting address details failed.');
      }
    };

    if (!!addressId) fetchAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressId, isAdvisor, userId]);

  const {street, apt, city, state, country, zipCode} = address;

  return !addressId
    ? {address: {}, formattedAddress: 'N/A', error: null}
    : {
        address,
        formattedAddress: !!address?.failed ? 'N/A' : getFullAddress(street, apt, city, state, country, zipCode),
        error
      };
};

export default useAddress;

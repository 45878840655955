import {createSlice} from '@reduxjs/toolkit';

// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  initiated: false,
  isLoading: false,
  error: null,
  chartData: {
    general: {},
    realEstate: {},
    collection: {},
    automobile: {},
    watercraft: {},
    aircraft: {},
    equine: {},
    divested: {}
  },
  shared: {
    realEstate: false,
    collection: false,
    automobile: false,
    watercraft: false,
    aircraft: false,
    equine: false
  },
  assets: {
    general: [],
    realEstate: [],
    collection: [],
    automobile: [],
    watercraft: [],
    aircraft: [],
    equine: []
  }
};

const slice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET All ASSETS
    setAssets(state, action) {
      state.isLoading = false;
      state.initiated = true;
      let newAssets = {};
      let chartData = {};
      console.log("action.payload of asset----------------", action.payload)
      for (const [key, value] of Object.entries(action.payload)) {
        if (key !== 'divested') newAssets[key] = [...value.data, ...state.assets[key].filter((item) => item.shared)];
        chartData[key] = value.topChartData;
      }

      state.assets = newAssets;
      state.chartData = chartData;
    },

    // GET Real Estate ASSETS
    setREAssets(state, action) {
      state.isLoading = false;
      state.assets.realEstate = [...action.payload.data, ...state.assets.realEstate.filter((item) => item.shared)];
      state.chartData.realEstate = action.payload.topChartData;
    },

    // GET Shared Real Estate ASSETS
    setSharedREAssets(state, action) {
      state.shared.realEstate = true;
      state.assets.realEstate = [...state.assets.realEstate.filter((item) => !item.shared), ...action.payload];
    },

    // GET Collection ASSETS
    setCOAssets(state, action) {
      state.isLoading = false;
      state.assets.collection = [...action.payload.data, ...state.assets.collection.filter((item) => item.shared)];
      state.chartData.collection = action.payload.topChartData;
    },

    // GET Shared Collection ASSETS
    setSharedCOAssets(state, action) {
      state.shared.collection = true;
      state.assets.collection = [...state.assets.collection.filter((item) => !item.shared), ...action.payload];
    },

    // GET Automobile ASSETS
    setAMAssets(state, action) {
      state.isLoading = false;
      state.assets.automobile = [...action.payload.data, ...state.assets.automobile.filter((item) => item.shared)];
      state.chartData.automobile = action.payload.topChartData;
    },

    // GET Shared Automobile ASSETS
    setSharedAMAssets(state, action) {
      state.shared.automobile = true;
      state.assets.automobile = [...state.assets.automobile.filter((item) => !item.shared), ...action.payload];
    },

    // GET Watercraft ASSETS
    setWAAssets(state, action) {
      state.isLoading = false;
      state.assets.watercraft = [...action.payload.data, ...state.assets.watercraft.filter((item) => item.shared)];
      state.chartData.watercraft = action.payload.topChartData;
    },

    // GET Shared Watercraft ASSETS
    setSharedWAAssets(state, action) {
      state.shared.watercraft = true;
      state.assets.watercraft = [...state.assets.watercraft.filter((item) => !item.shared), ...action.payload];
    },

    // GET Aircraft ASSETS
    setAIAssets(state, action) {
      state.isLoading = false;
      state.assets.aircraft = [...action.payload.data, ...state.assets.aircraft.filter((item) => item.shared)];
      state.chartData.aircraft = action.payload.topChartData;
    },

    // GET Shared Aircraft ASSETS
    setSharedAIAssets(state, action) {
      state.shared.aircraft = true;
      state.assets.aircraft = [...state.assets.aircraft.filter((item) => !item.shared), ...action.payload];
    },

    // GET Equine ASSETS
    setEQAssets(state, action) {
      state.isLoading = false;
      state.assets.equine = [...action.payload.data, ...state.assets.equine.filter((item) => item.shared)];
      state.chartData.equine = action.payload.topChartData;
    },

    // GET Shared Equine ASSETS
    setSharedEQAssets(state, action) {
      state.shared.equine = true;
      state.assets.equine = [...state.assets.equine.filter((item) => !item.shared), ...action.payload];
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllAssets() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/asset/all');
      const assets = response.data;
      // console.log("response.data assets---------------------------", response.data)
      dispatch(slice.actions.setAssets(assets));
      return assets;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getSharedAssets(tab) {
  return async (dispatch) => {
    try {
      if (tab === 'realEstate') {
        const shared = await axios.get('/shared/assets/real-estate');

        dispatch(slice.actions.setSharedREAssets(shared.data));
      } else if (tab === 'collection') {
        const shared = await axios.get('/shared/assets/collection');

        dispatch(slice.actions.setSharedCOAssets(shared.data));
      } else if (tab === 'automobile') {
        const shared = await axios.get('/shared/assets/automobile');

        dispatch(slice.actions.setSharedAMAssets(shared.data));
      } else if (tab === 'watercraft') {
        const shared = await axios.get('/shared/assets/watercraft');

        dispatch(slice.actions.setSharedWAAssets(shared.data));
      } else if (tab === 'aircraft') {
        const shared = await axios.get('/shared/assets/aircraft');

        dispatch(slice.actions.setSharedAIAssets(shared.data));
      } else if (tab === 'equine') {
        const shared = await axios.get('/shared/assets/equine');

        dispatch(slice.actions.setSharedEQAssets(shared.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAsset(data) {
  return async (dispatch) => {
    const payload = {
      ...data
    };
    dispatch(slice.actions.startLoading());
    try {
      const tab = payload.type;
      if (tab === 'realEstate') {
        const result = await axios.post('/asset/real-estate', payload);
        const response = await axios.get('/asset/real-estate');
        const assets = response.data;

        dispatch(slice.actions.setREAssets(assets));
        return result;
      } else if (tab === 'collection') {
        const result = await axios.post('/asset/collection', payload);
        const response = await axios.get('/asset/collection');
        const assets = response.data;

        dispatch(slice.actions.setCOAssets(assets));
        return result;
      } else if (tab === 'automobile') {
        const result = await axios.post('/asset/automobile', payload);
        const response = await axios.get('/asset/automobile');
        const assets = response.data;

        dispatch(slice.actions.setAMAssets(assets));
        return result;
      } else if (tab === 'watercraft') {
        const result = await axios.post('/asset/watercraft', payload);
        const response = await axios.get('/asset/watercraft');
        const assets = response.data;

        dispatch(slice.actions.setWAAssets(assets));
        return result;
      } else if (tab === 'aircraft') {
        const result = await axios.post('/asset/aircraft', payload);
        const response = await axios.get('/asset/aircraft');
        const assets = response.data;

        dispatch(slice.actions.setAIAssets(assets));
        return result;
      } else if (tab === 'equine') {
        const result = await axios.post('/asset/equine', payload);
        const response = await axios.get('/asset/equine');
        const assets = response.data;

        dispatch(slice.actions.setEQAssets(assets));
        return result;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function updateAsset(data, userId, isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const payload = {
      ...data
    };
    const tab = payload.type;
    try {
      let result;
      let response;
      let assets = {
        data: [],
        topChartData: []
      };

      if (tab === 'realEstate') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/asset/real-estate?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/asset/real-estate?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push({...response.data});

          dispatch(slice.actions.setREAssets(assets));
        } else {
          result = await axios.put('/asset/real-estate', payload);
          response = await axios.get('/asset/real-estate');

          dispatch(slice.actions.setREAssets(response.data));
        }
        return result?.data;
      } else if (tab === 'collection') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/asset/collection?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/asset/collection?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push({...response.data});

          dispatch(slice.actions.setCOAssets(assets));
        } else {
          result = await axios.put('/asset/collection', payload);
          response = await axios.get('/asset/collection');

          dispatch(slice.actions.setCOAssets(response.data));
        }
        return result?.data;
      } else if (tab === 'automobile') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/asset/automobile?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/asset/automobile?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push({...response.data});

          dispatch(slice.actions.setAMAssets(assets));
        } else {
          result = await axios.put('/asset/automobile', payload);
          response = await axios.get('/asset/automobile');
          assets = {...response.data};

          dispatch(slice.actions.setAMAssets(assets));
        }
        return result?.data;
      } else if (tab === 'watercraft') {
        if (!!userId) {
          result = await axios.put(
            `/advisor/asset/watercraft?userId=${userId}&isFamilyGroup=${isFamilyGroup}`,
            payload
          );
          response = await axios.get(
            `/advisor/asset/watercraft?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push({...response.data});

          dispatch(slice.actions.setWAAssets(assets));
        } else {
          result = await axios.put('/asset/watercraft', payload);
          response = await axios.get('/asset/watercraft');

          dispatch(slice.actions.setWAAssets(response.data));
        }
        return result?.data;
      } else if (tab === 'aircraft') {
        if (!!userId) {
          result = await axios.put(`/advisor/asset/aircraft?userId=${userId}&isFamilyGroup=${isFamilyGroup}`, payload);
          response = await axios.get(
            `/advisor/asset/aircraft?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push({...response.data});

          dispatch(slice.actions.setAIAssets(assets));
        } else {
          result = await axios.put('/asset/aircraft', payload);
          response = await axios.get('/asset/aircraft');

          dispatch(slice.actions.setAIAssets(response.data));
        }
        return result?.data;
      } else if (tab === 'equine') {
        if (!!userId) {
          result = await axios.put(`/advisor/asset/equine?userId=${userId}&isFamilyGroup=${isFamilyGroup}`, payload);
          response = await axios.get(
            `/advisor/asset/equine?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push({...response.data});

          dispatch(slice.actions.setEQAssets(assets));
        } else {
          result = await axios.put('/asset/equine', payload);
          response = await axios.get('/asset/equine');

          dispatch(slice.actions.setEQAssets(response.data));
        }
        return result?.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function deleteAsset(data, isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const payload = {
      ...data
    };
    const {tab, id, userId} = payload;
    try {
      let response;
      let result;
      let assets = {
        data: [],
        topChartData: []
      };
      if (tab === 'realEstate') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/asset/real-estate?userId=${userId}&id=${id}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/asset/real-estate?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push(response.data);
        } else {
          result = await axios.delete(`/asset/real-estate/${id}`);
          response = await axios.get('/asset/real-estate');
          assets = {...response.data};
        }

        dispatch(slice.actions.setREAssets(assets));
        return result?.data;
      } else if (tab === 'collection') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/asset/collection?userId=${userId}&id=${id}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/asset/collection?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push(response.data);
        } else {
          result = await axios.delete(`/asset/collection/${id}`);
          response = await axios.get('/asset/collection');
          assets = {...response.data};
        }

        dispatch(slice.actions.setCOAssets(assets));
        return result?.data;
      } else if (tab === 'automobile') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/asset/automobile?userId=${userId}&id=${id}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/asset/automobile?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push(response.data);
        } else {
          result = await axios.delete(`/asset/automobile/${id}`);
          response = await axios.get('/asset/automobile');
          assets = {...response.data};
        }

        dispatch(slice.actions.setAMAssets(assets));
        return result?.data;
      } else if (tab === 'watercraft') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/asset/watercraft?userId=${userId}&id=${id}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/asset/watercraft?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push(response.data);
        } else {
          result = await axios.delete(`/asset/watercraft/${id}`);
          response = await axios.get('/asset/watercraft');
          assets = {...response.data};
        }

        dispatch(slice.actions.setWAAssets(assets));
        return result?.data;
      } else if (tab === 'aircraft') {
        if (!!userId) {
          result = await axios.delete(
            `/advisor/asset/aircraft?userId=${userId}&id=${id}&isFamilyGroup=${isFamilyGroup}`
          );
          response = await axios.get(
            `/advisor/asset/aircraft?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push(response.data);
        } else {
          result = await axios.delete(`/asset/aircraft/${id}`);
          response = await axios.get('/asset/aircraft');
          assets = {...response.data};
        }

        dispatch(slice.actions.setAIAssets(assets));
        return result?.data;
      } else if (tab === 'equine') {
        if (!!userId) {
          result = await axios.delete(`/advisor/asset/equine?userId=${userId}&id=${id}&isFamilyGroup=${isFamilyGroup}`);
          response = await axios.get(
            `/advisor/asset/equine?id=${payload.id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
          );
          assets.data.push(response.data);
        } else {
          result = await axios.delete(`/asset/equine/${id}`);
          response = await axios.get('/asset/equine');
          assets = {...response.data};
        }

        dispatch(slice.actions.setEQAssets(assets));
        return result?.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

import { useRef, useState, useCallback } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import {  useNavigate, useLocation } from 'react-router-dom';
import arrowForwardFill from '@iconify/icons-eva/arrow-forward-fill';
// mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Button, Paper, Typography, CardContent } from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
// utils
import { fCurrency } from 'src/utils/formatNumber';
// route
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import { CarouselControlsArrowsBasic2 } from 'src/components/carousel/controls';
// constants
import { getVehicleTypeLabel } from 'src/constants/dropdown';
import DUMMY from './asset/dummy.jpg';
import ShowIdPage from './ShowId';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  overflow: 'hidden',
  position: 'relative'
});

const CarouselImgStyle = styled('img')(({ theme }) => ({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  transition: theme.transitions.create('all')
}));

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object
};

function CarouselItem({ item }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { members } = useSelector((state) => state.familyGroup);
  const [open, setOpen] = useState(false);
  const memberSelected = members.filter((m) => m.show);
  const isAdvisor = user.role === 'Advisor';
  const {
    id,
    nickName,
    imageFiles = [],
    vehicleType,
    year,
    make,
    customMake,
    amModel,
    idNumber,
    marketValue,
    user: owner
  } = item;

  const handleClick = () => {
    if (isAdvisor || memberSelected?.length > 0) {
      const route = `${PATH_DASHBOARD.root}/asset/automobile/${id}/${owner}`;
      navigate(route, { state });
    } else {
      const route = `${PATH_DASHBOARD.root}/asset/automobile/${id}`;
      navigate(route, { state });
    }
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    if (state?.goBack) navigate(-1);
  }, [setOpen, navigate, state]);


  const {
    rPolicies = [], // Related Policies
  } = item;

  const combinedAidcards = rPolicies.flatMap(policy => policy.aidCard);

  return (
    <Paper
      sx={{
        mx: 1,
        borderRadius: 2,
        overflow: 'hidden',
        paddingTop: '300px',
        position: 'relative',
        '&:hover img': {
          width: '120%',
          height: '120%'
        }
      }}
    >
      <CarouselImgStyle alt="preview" src={imageFiles.length > 0 ? imageFiles[0].documentLink : DUMMY} />
      <CardContent
        sx={{
          bottom: 0,
          zIndex: 9,
          width: '100%',
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white',
          backgroundImage: (theme) =>
            `linear-gradient(to top, ${theme.palette.grey[900]} 0%,${alpha(theme.palette.grey[900], 0)} 100%)`
        }}
      >
        <Typography variant="h4" paragraph>
          {nickName}
        </Typography>
        <Typography variant="body1">{getVehicleTypeLabel(vehicleType)}</Typography>
        <Typography variant="body1">{year}</Typography>
        <Typography variant="body1">{make === 'custom' ? customMake : make}</Typography>
        <Typography variant="body1">{amModel}</Typography>
        <Typography variant="body1">{idNumber}</Typography>
        <Typography variant="body1">{fCurrency(marketValue)}</Typography>
        <Button
          variant="outlined"
          color="inherit"
          onClick={handleClick}
          sx={{
            mt: 2,
            opacity: 0.72,
            alignItems: 'center',
            display: 'inline-flex',
            transition: (theme) => theme.transitions.create('opacity'),
            '&:hover': { opacity: 1 }
          }}
        >
          View Asset <Box component={Icon} icon={arrowForwardFill} sx={{ width: 16, height: 16, ml: 1 }} />
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => setOpen(true)} // Fix: Wrap in arrow function
          sx={{
            mt: 2,
            opacity: 0.72,
            alignItems: 'center',
            display: 'inline-flex',
            marginLeft: '3px',
            transition: (theme) => theme.transitions.create('opacity'),
            '&:hover': { opacity: 1 }
          }}
        >
          View ID <Box component={Icon} icon={arrowForwardFill} sx={{ width: 16, height: 16, ml: 1 }} />
        </Button>
        <ShowIdPage open={open} handleClose={handleClose} data={combinedAidcards} />

      </CardContent>
    </Paper>
  );
}

export default function Gallery({ data }) {
  const carouselRef = useRef();
  const theme = useTheme();

  const settings = {
    slidesToShow: 3,
    infinite: data.length > 3,
    centerMode: true,
    centerPadding: '60px',
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, infinite: data.length > 2 }
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, infinite: data.length > 1, centerPadding: '0' }
      }
    ]
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <RootStyle>
      <Slider ref={carouselRef} {...settings}>
        {data.map((item) => (
          <CarouselItem key={item.id} item={item} />
        ))}
      </Slider>
      <CarouselControlsArrowsBasic2 onNext={handleNext} onPrevious={handlePrevious} />
    </RootStyle>
  );
}

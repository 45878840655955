import React from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
// mui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  MenuItem,
  Switch,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addAsset} from 'src/redux/slices/asset';
import {addClientAsset} from 'src/redux/slices/client';
// utils
import {getNickName} from 'src/utils/formatNickname';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import NumberFormat from 'src/components/NumberFormat';
import {
  AddressFields,
  ContactSelect,
  FormikErrorNotification,
  TextFieldWithHover
} from 'src/components/_dashboard/common';
// constants
import {WA_TYPE, WA_MODEL, WA_MOORING_TYPE, ASSET_STATUS} from 'src/constants/dropdown';
import {fDateZeroTime} from 'src/utils/formatTime';
// ----------------------------------------------------------------------
const TYPE = 'watercraft';

AircraftDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  inLoading: PropTypes.bool,
  policies: PropTypes.array.isRequired
};

export default function AircraftDialog({handleClose, clientId, open, inLoading = false, policies = []}) {
  const {user} = useAuth();
  const isAdvisor = user && user.role === 'Advisor';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const AddSchema = Yup.object().shape({
    imageFiles: Yup.array(),
    wcModel: Yup.string().required('Please provide Model'),
    maker: Yup.string().required('Please provide Maker'),
    year: Yup.number().required('Please provide year'),
    regExpDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    capDob: Yup.date().nullable().min(new Date(1900, 0, 1)),
    lmAppDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    lrAppDate: Yup.date().nullable().min(new Date(1900, 0, 1))
  });

  const formik = useFormik({
    initialValues: {
      vesselType: '',
      year: '',
      nickName: '',
      maker: '',
      wcModel: '',
      hullID: '', // Hull Identification Number
      moorType: '', // Mooring Type
      // Mooring Address
      mooringAddressId: '',
      isRegistered: false, // Registration Indicator
      // Registration Address
      registeredAddressId: '',
      regExpDate: null, // Registration Expiration Date
      owner: [], // Deeded Owner
      addInterest: [], // Additional Interest
      huIndicator: false, // Hurricane Plan Indicator
      huPlDocs: [], // Hurricane Plan Docs
      capIndicator: false, // Captain Indicator
      captain: [], // Captain
      capResumeDocs: [], // Captain Resume Docs
      marketValue: '', // Market Value
      lmAppDate: null, // Last Market Appraisal Date
      lmAppValue: '', // Last Market Appraisal Value
      repValue: '', // Replacement Value
      lrAppDate: null, // Last Replacement Appraisal Date
      lrAppValue: '', // Last Replacement Appraisal Value
      assetStatus: 'active',
      insured: false,
      insuredValue: '',
      rPolicies: [], // Related Policies
      note: '',
      mValueDocs: [], // Market Value Docs
      rValueDocs: [], // Replacement Value Docs
      imageFiles: []
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, nickName: values.nickName || getNickName(values, 'watercraft'), type: TYPE};
        const res = !!clientId
          ? await dispatch(addClientAsset(payload, clientId, !isAdvisor))
          : await dispatch(addAsset(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('New Asset has been created.', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/asset/watercraft/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/asset/watercraft/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Asset creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, resetForm, getFieldProps, setFieldValue, handleSubmit, isSubmitting, touched, errors} = formik;

  

  const handleChangeMake = (value) => {
    setFieldValue('maker', value);
    setFieldValue('wcModel', '');
  };



  const handleCancelClick = () => {
    resetForm();
    handleClose();
  };

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Add New Asset (Watercraft)</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Vessel Type"
                  {...getFieldProps('vesselType')}
                  onChange={(e) => setFieldValue('vesselType', e.target.value)}
                >
                  {WA_TYPE.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  {...getFieldProps('year')}
                  fullWidth
                  type="number"
                  label="Year"
                  error={Boolean(touched.year && errors.year)}
                  helperText={touched.year && errors.year}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextFieldWithHover
                  {...getFieldProps('nickName')}
                  fullWidth
                  type="string"
                  label="Asset Name"
                  info="Input the asset name"
                />
                <Autocomplete
                  fullWidth
                  options={WA_MODEL.map((item) => item.maker).filter(
                    (item, index, array) => array.indexOf(item) === index
                  )}
                  freeSolo
                  value={values.maker}
                  onChange={(e, value) => handleChangeMake(value)}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      label="Make"
                      variant="outlined"
                      onChange={(e) => handleChangeMake(e.target.value)}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <Autocomplete
                  fullWidth
                  options={WA_MODEL.filter((item) => item.maker === values.maker).map((item) => item.model)}
                  freeSolo
                  value={values.wcModel}
                  onChange={(e, value) => setFieldValue('wcModel', value)}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      label="Model"
                      variant="outlined"
                      onChange={(e) => setFieldValue('wcModel', e.target.value)}
                    />
                  )}
                />
                <TextField
                  {...getFieldProps('hullID')}
                  fullWidth
                  type="text"
                  label="Hull Identification Number"
                  inputProps={{style: {textTransform: 'uppercase'}}}
                />
                <TextField
                  select
                  fullWidth
                  label="Mooring Type"
                  {...getFieldProps('moorType')}
                  onChange={(e) => setFieldValue('moorType', e.target.value)}
                >
                  {WA_MOORING_TYPE.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Typography>Mooring Address</Typography>
              <AddressFields
                isRealEstate={false}
                addId={values.mooringAddressId}
                handleChange={(id) => setFieldValue('mooringAddressId', id)}
              />
              <Box>
                <FormControlLabel
                  control={<Switch {...getFieldProps('isRegistered')} checked={values.isRegistered} />}
                  label="Registered"
                  labelPlacement="start"
                />
              </Box>
              {values.isRegistered && (
                <>
                  <Typography>Registration Address</Typography>
                  <AddressFields
                    isRealEstate={false}
                    addId={values.registeredAddressId}
                    handleChange={(id) => setFieldValue('registeredAddressId', id)}
                  />
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <DatePicker
                      fullWidth
                      label="Registration Expiration Date"
                      {...getFieldProps('regExpDate')}
                      onChange={(date) => setFieldValue('regExpDate', fDateZeroTime(date))}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Stack>
                </>
              )}
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <ContactSelect
                  clientId={clientId}
                  keyValue="owner"
                  label="Owner"
                  values={values.owner}
                  setFieldValue={setFieldValue}
                />
                <ContactSelect
                  clientId={clientId}
                  keyValue="addInterest"
                  label="Additional Interest"
                  values={values.addInterest}
                  setFieldValue={setFieldValue}
                />
              </Stack>
              <Box>
                <FormControlLabel
                  control={<Switch {...getFieldProps('huIndicator')} checked={values.huIndicator} />}
                  label="Hurricane Plan Indicator"
                  labelPlacement="start"
                />
              </Box>
              
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <Box sx={{width: '100%'}}>
                  <FormControlLabel
                    control={<Switch {...getFieldProps('capIndicator')} checked={values.capIndicator} />}
                    label="Captain Indicator"
                    labelPlacement="start"
                  />
                </Box>
                {values.capIndicator && (
                  <ContactSelect
                    clientId={clientId}
                    keyValue="captain"
                    label="Captain"
                    values={values.captain}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Stack>
             
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <NumberFormat
                  fullWidth
                  label="Market Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('marketValue')}
                  onChange={(e) => setFieldValue('marketValue', parseFloat(e.target.values.value))}
                />
                <DatePicker
                  fullWidth
                  label="Last Market Appraisal Date"
                  {...getFieldProps('lmAppDate')}
                  onChange={(date) => setFieldValue('lmAppDate', fDateZeroTime(date))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <NumberFormat
                  fullWidth
                  label="Last Market Appraisal Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('lmAppValue')}
                  onChange={(e) => setFieldValue('lmAppValue', parseFloat(e.target.values.value))}
                />
                <NumberFormat
                  fullWidth
                  label="Replacement Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('repValue')}
                  onChange={(e) => setFieldValue('repValue', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <DatePicker
                  fullWidth
                  label="Last Replacement Appraisal Date"
                  {...getFieldProps('lrAppDate')}
                  onChange={(date) => setFieldValue('lrAppDate', fDateZeroTime(date))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
                <NumberFormat
                  fullWidth
                  label="Last Replacement Appraisal Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('lrAppValue')}
                  onChange={(e) => setFieldValue('lrAppValue', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Asset Status"
                  {...getFieldProps('assetStatus')}
                  onChange={(e) => setFieldValue('assetStatus', e.target.value)}
                  helperText="Please select asset status."
                >
                  {ASSET_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <Box sx={{width: '100%'}}>
                  <FormControlLabel
                    control={<Switch {...getFieldProps('insured')} checked={values.insured} />}
                    label="Insured"
                    labelPlacement="start"
                  />
                </Box>
                {values.insured && (
                  <NumberFormat
                    fullWidth
                    label="Insured Value"
                    prefix="$"
                    placeholder="$0.00"
                    {...getFieldProps('insuredValue')}
                    onChange={(e) => setFieldValue('insuredValue', parseFloat(e.target.values.value))}
                  />
                )}
              </Stack>
              <Autocomplete
                fullWidth
                multiple
                loading={inLoading}
                options={policies}
                ListboxProps={{sx: {maxHeight: '160px'}}}
                disableCloseOnSelect
                getOptionLabel={(option) => `Watercraft - ${option.policyNumber} - ${option.nickName}`}
                value={policies.filter((item) => values.rPolicies.some((pol) => pol.id === item.id))}
                onChange={(e, value) =>
                  setFieldValue(
                    'rPolicies',
                    value.map((item) => ({id: item.id, type: 'watercraft'}))
                  )
                }
                renderOption={(props, option, {selected}) => {
                  return (
                    <li {...props} key={option.id}>
                      <Checkbox checked={selected} />
                      Watercraft - {option.policyNumber} - {option.nickName}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Related Policies" />}
              />
              <Stack spacing={2}>
                <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
              </Stack>
              
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelClick} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}

import React, {useState, useMemo, useEffect} from 'react';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
// mui
import {Box, Card, CardContent, CardHeader, FormControlLabel, Grid, Stack, Switch, Typography, CircularProgress} from '@mui/material';
// components
import EmptyContent from 'src/components/EmptyContent';
import Gallery from './components/RealEstate/Gallery';
import Table from './components/General/Table';

import {useSnackbar} from 'notistack';

// api
import {getDashboardData} from 'src/api';
import {getAdvisorDashboardData} from 'src/api/advisor';

// chart components
// import TotalNetWorthChart from '../general-app/chart-components/TotalNetWorthChart';
import TotalLCoverChart from '../general-app/chart-components/TotalLCoverChart';
import TotalInsuranceChart from '../general-app/chart-components/TotalInsuranceChart';
import FullScreenChart from '../common/FullScreenChart';
import TotalInsuranceColumnChart from '../general-app/chart-components/TotalInsuranceColumnChart';

// utils
import {filterDCByClient} from 'src/utils/advisor';
import {isNullOrEmpty} from 'src/utils/checkEmpty';
import { LabelWidget } from '../common';

// hooks
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
// redux
import {useSelector} from 'src/redux/store';
// components
import ComingSoonCover from 'src/components/ComingSoonCover';

General.propTypes = {
  mode: PropTypes.string,
  view: PropTypes.string,
  data: PropTypes.array
};

export default function General({mode, view = 'show', data = []}) {
  const [viewAll, setViewAll] = useState(false);
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';
  // const {list} = useSelector((state) => state.client);
  const {trendShow} = useSettings();
  const {enqueueSnackbar} = useSnackbar();

   // NOTE: Advisor flow
   const {list, isLoading} = useSelector((state) => state.client);

  // NOTE: Client flow with Family group data
  const {members} = useSelector((state) => state.familyGroup);
  const smCounts = members.filter((m) => m.show).length; // NOTE: Selected Family Member counts

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [_data, setData] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const getData = async () => {
      setLoading(true);
      try {
        if (user.role === 'Advisor' || smCounts > 0) {
          const res = await getAdvisorDashboardData(user.role !== 'Advisor');
          if (isMounted) {
            setData(res?.data);
          }
        } else {
          const res = await getDashboardData();
          if (isMounted) {
            setUserData(res?.data);
          }
        }
      } catch (e) {
        if (isMounted) {
          console.warn(e);
          enqueueSnackbar(e?.message || 'Get dashboard info error.', {variant: 'error'});
        }
      } finally {
        setLoading(false);
      }
    };

    getData();

    return () => (isMounted = false);
  }, [enqueueSnackbar, user, smCounts]);

  const aggregatedData = useMemo(() => {
    console.log("data---------------------------", data)
    if (!isAdvisor) return data;
    return list.flatMap((client) => client.assets || []);
  }, [isAdvisor, data, list]);
  console.log(list);

  const getValidData = () => {
    return viewAll ? aggregatedData : aggregatedData.filter((item) => item.assetStatus !== 'divested');
  };

  const calculateChartData = () => {
    const validData = getValidData();
    console.log("validData-----------------", validData);
    const statesSet = new Set();

    const totalMarketValue = validData.reduce((sum, asset) => {
      if (asset.type === 'collection') {
        return sum + Math.max(asset.estateValue || 0, asset.purchasePrice || 0);
      }
      return sum + (asset.marketValue || 0);
    }, 0);

    const totalInsurableValue = validData.reduce((sum, asset) => {
      if (asset.type === 'collection') {
        return sum + (asset.replacementValue || 0);
      }
      return sum + (asset.insuredValue || 0);
    }, 0);

    validData.forEach((asset) => {
      switch (asset.type) {
        case 'realEstate':
          statesSet.add(asset.address?.state);
          break;
        case 'collection':
          statesSet.add(asset.loanAddress?.state || asset.address?.state);
          break;
        case 'automobile':
          statesSet.add(asset.stateRegistered);
          break;
        case 'watercraft':
        case 'aircraft':
          statesSet.add(asset.registrationAddress?.state);
          break;
        case 'equine':
          statesSet.add(asset.locationAddress?.state);
          break;
        default:
          break;
      }
    });

    const insurableValueBreakdown = validData.reduce((acc, asset) => {
      const type = asset.type || 'Other';
      acc[type] =
        (acc[type] || 0) +
        (asset.type === 'collection' ? asset.replacementValue || 0 : asset.insuredValue || 0);
      return acc;
    }, {});

    return {
      numberOfAssets: validData.length,
      totalMarketValue,
      totalStatesWithExposure: statesSet.size,
      totalInsurableValue: {
        total: totalInsurableValue,
        breakdown: insurableValueBreakdown
      }
    };
  };

  const advisorData = useMemo(() => {
    const selectedList = list.filter((item) => item.show);
    let familyCount = 0;
    let avClients = 0;
    // NOTE: grouped clients
    const groupedList = {};
    selectedList.forEach((client) => {
      if (client?.familyGroup?.length === 0) {
        const groupName = 'No Family Group';
        const groupId = 'no-family-group';

        if (!groupedList[groupId]) {
          groupedList[groupId] = {groupName, clients: []};
        }
        groupedList[groupId].clients.push(client);
      } else {
        client?.familyGroup?.forEach((group) => {
          const groupName = group.groupName;
          const groupId = group.id;

          if (!groupedList[groupId]) {
            groupedList[groupId] = {groupName, clients: []};
          }
          groupedList[groupId].clients.push(client);
        });
      }
    });

    if (!!Object.keys(groupedList).length) {
      const noFamilyClients = groupedList['no-family-group']?.clients?.length;
      delete groupedList['no-family-group'];

      familyCount = Object.keys(groupedList).length + noFamilyClients;
      const totalClients =
        Object.values(groupedList).reduce((sum, family) => sum + family.clients.length, 0) + noFamilyClients;
      avClients = totalClients / (familyCount || 1);
    }

    return {totalClients: selectedList.length, totalFamilies: familyCount, averageClients: avClients};
  }, [list]);
  

  const chartData = isAdvisor
    ? filterDCByClient(JSON.parse(JSON.stringify(_data)) || [], JSON.parse(JSON.stringify(list)))
    : members.some((m) => m.show)
    ? filterDCByClient(JSON.parse(JSON.stringify(_data)) || [], JSON.parse(JSON.stringify(members)))
    : userData || {};

  // console.log('General ChartData-------------------------', chartData);

  const calculatedChartData = calculateChartData();
  // console.log('calculatedCharData------------------------', calculatedChartData);

  const renderCharts = () => (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <CardContent>
              <Typography variant="h6">{isAdvisor ? 'Total Assets Under Advisement' : 'Number of Assets'}</Typography>
              <Box sx={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="h3">{calculatedChartData.numberOfAssets}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <CardContent>
              <Typography variant="h6">
                {isAdvisor ? 'Total Market Value Under Advisement' : 'Total Market Value'}
              </Typography>
              <Box sx={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="h3">{calculatedChartData.totalMarketValue}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <CardContent>
              <Typography variant="h6">Total States with Exposure</Typography>
              <Box sx={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="h3">{calculatedChartData.totalStatesWithExposure}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              overflow: 'visible'
            }}
          >
            {!loading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 999999,
                  py: 1,
                  px: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* <Typography fontSize={'10px'} color={'text.secondary'}>
                  Trend
                </Typography>
                <Switch size="small" checked={trendShow} onChange={(e) => onChangeTrend(e.target.checked)} /> */}
              </Box>
            )}

            {((isNullOrEmpty(chartData.InsurancePremium) && !trendShow) ||
              (isNullOrEmpty(chartData.InsurancePremiumHistory) && trendShow) ||
              (isNullOrEmpty(chartData.AggregatedPropertyValue) && trendShow)) &&
              !loading && <ComingSoonCover noData={true} style={{marginTop: 35}} />}

            <CardHeader title={isAdvisor ? 'Total Insurable Value Under Advisement' : 'Total Insurable Value'} />

            {trendShow ? (
              <FullScreenChart
                withTrend
                isEmpty={
                  isNullOrEmpty(chartData.InsurancePremiumHistory) || isNullOrEmpty(chartData.AggregatedPropertyValue)
                }
              >
                <TotalInsuranceColumnChart
                  data1={chartData.InsurancePremiumHistory}
                  data2={chartData.AggregatedPropertyValue}
                  label={['Annual Premium', 'Aggregate Property Value']}
                  loading={loading}
                />
              </FullScreenChart>
            ) : (
              // <TotalInsuranceChart data={chartData.InsurancePremium} loading={loading} />
              <TotalInsuranceChart data={calculatedChartData.totalInsurableValue.breakdown} loading={loading} />
            )}
          </Card>
        </Grid>

        {isAdvisor && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{m: 1, height: {xs: 'auto', sm: 'calc(100% - 12px)'}, display: 'flex', flexDirection: 'column'}}>
              {isNullOrEmpty(chartData.totalPropertyCoverage) && !loading && (
                <ComingSoonCover noData={true} style={{marginTop: 20}} />
              )}
              <CardHeader title="Total Property Coverage Under Advisement" />
              <TotalLCoverChart data={chartData.totalPropertyCoverage} loading={loading} />
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {isLoading ? (
              <Card
                sx={{m: 1, height: {xs: 'auto', sm: 'calc(100% - 12px)'}, display: 'flex', flexDirection: 'column'}}
              >
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                  <CircularProgress />
                </Box>
              </Card>
            ) : (
              <Stack sx={{m: 1, height: 'calc(100% - 12px)', justifyContent: 'space-between'}}>
                <LabelWidget label="Total Clients" data={advisorData.totalClients} sx={{height: '30%'}} />
                <LabelWidget label="Total Families" data={advisorData.totalFamilies} sx={{height: '30%'}} />
                <LabelWidget label="Average client per family" data={advisorData.averageClients} sx={{height: '30%'}} />
              </Stack>
            )}
          </Grid>
        </>
      )}
      </Grid>
  );

  const renderData = () =>
    mode === 'gallery' ? (
      <Gallery data={aggregatedData} />
    ) : (
      <Box sx={{minHeight: 360}}>
        {view === 'show' && renderCharts()}
        <FormControlLabel
          sx={{position: 'absolute', top: '348px', right: '46px', zIndex: 999}}
          control={<Switch size="small" checked={viewAll} onChange={(event) => setViewAll(event.target.checked)} />}
          label={viewAll ? 'View Active' : 'View All'}
          labelPlacement="start"
        />
        <Table data={getValidData()} />
      </Box>
    );

  return (
    <Stack spacing={2}>
      {view === 'show' && !isEmpty(chartData) && (
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Typography></Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography></Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography></Typography>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>{aggregatedData.length > 0 ? renderData() : <EmptyContent />}</CardContent>
      </Card>
    </Stack>
  );
}

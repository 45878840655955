import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import {Stack, Typography} from '@mui/material';
import {alpha, styled} from '@mui/material/styles';
import checkmarkCircle2Outline from '@iconify/icons-eva/checkmark-circle-2-outline';
import minusCircleOutline from '@iconify/icons-eva/minus-circle-outline';
import closeCircleOutline from '@iconify/icons-eva/close-circle-outline';

const commonIconStyle = {
  height: '24px',
  padding: '4px',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center'
};

export const SuccessIconStyle = styled('div')(({theme}) => ({
  ...commonIconStyle,
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.16)
}));

export const ErrorIconStyle = styled('div')(({theme}) => ({
  ...commonIconStyle,
  marginRight: theme.spacing(1),
  color: theme.palette.error.main,
  backgroundColor: alpha(theme.palette.error.main, 0.16)
}));

export const NeutralIconStyle = styled('div')(({theme}) => ({
  ...commonIconStyle,
  marginRight: theme.spacing(1),
  color: theme.palette.grey['500'],
  backgroundColor: alpha(theme.palette.grey['900'], 0.16)
}));

StatusItem.propTypes = {
  flag: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  handleClick: PropTypes.func,
  sx: PropTypes.object
};

export default function StatusItem({flag, label, handleClick, sx, disabled}) {
  return (
    <Stack direction="row" sx={{cursor: disabled ? 'not-allowed' : handleClick && 'pointer', py: '2px'}} spacing={1}>
      {disabled ? (
        <NeutralIconStyle>
          <Icon width={16} height={16} icon={minusCircleOutline} />
        </NeutralIconStyle>
      ) : flag ? (
        <SuccessIconStyle>
          <Icon width={16} height={16} icon={checkmarkCircle2Outline} onClick={handleClick} />
        </SuccessIconStyle>
      ) : (
        <ErrorIconStyle>
          <Icon width={16} height={16} icon={closeCircleOutline} onClick={handleClick} />
        </ErrorIconStyle>
      )}
      <Typography sx={sx}>{label}</Typography>
    </Stack>
  );
}

import React from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
// mui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addAsset} from 'src/redux/slices/asset';
import {addClientAsset} from 'src/redux/slices/client';
// utils
import {getNickName} from 'src/utils/formatNickname';
import {fDateZeroTime} from 'src/utils/formatTime';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import NumberFormat from 'src/components/NumberFormat';
import {
  AddressFields,
  ContactSelect,
  FormikErrorNotification,
  TextFieldWithHover
} from 'src/components/_dashboard/common';
// constants
import {AC_TYPE, AC_MODEL, AC_STORE_TYPE, ASSET_STATUS} from 'src/constants/dropdown';
import {US_STATES} from 'src/constants/states';
import {COUNTRY_LIST} from 'src/constants/countries';
// ----------------------------------------------------------------------

AircraftDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  inLoading: PropTypes.bool,
  policies: PropTypes.array.isRequired
};

export default function AircraftDialog({handleClose, clientId, open, inLoading = false, policies = []}) {
  const {user} = useAuth();
  const isAdvisor = user && user.role === 'Advisor';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const AddSchema = Yup.object().shape({
    insured: Yup.bool(),
    imageFiles: Yup.array(),
    acModel: Yup.string().required('Please provide Model'),
    maker: Yup.string().required('Please provide Maker'),
    year: Yup.number().required('Please provide year'),
    lastMADate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    lRADate: Yup.date().nullable().min(new Date(1900, 0, 1))
  });

  const formik = useFormik({
    initialValues: {
      craftType: '',
      year: '',
      nickName: '',
      maker: '',
      acModel: '',
      regState: '', // Registration State
      regCountry: '',
      hullID: '', // Hull Identification Number
      faaNumber: '', // FAA Number
      owner: [],
      addInterest: [], // Additional Interest
      pilots: [], // Pilot
      nCrew: 0, // number of Crew Seat
      nPassenger: 0, // number of Passenger Seat
      registeredLocationId: '',
      hangarLocationId: '',
      iataNumber: '', // IATA location Identifier
      storeType: '', // Storage Type
      marketValue: '',
      lastMADate: null, // Last Market Appraisal Date
      lastMAValue: '', // Last Market Appraisal Value
      rValue: '', // Replacement Value
      lRADate: null, // Last Replacement Appraisal Date
      lRAValue: '', // Last Replacement Appraisal Value
      assetStatus: 'active',
      insured: false,
      insuredValue: '',
      rPolicies: [], // Related Policies
      note: '',
      marketValueDocs: [], // Market Value Documents
      rValueDocs: [], // Replacement Value Documents
      imageFiles: []
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, nickName: values.nickName || getNickName(values, 'aircraft'), type: 'aircraft'};
        const res = !!!!clientId
          ? await dispatch(addClientAsset(payload, clientId, !isAdvisor))
          : await dispatch(addAsset(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('New Asset has been created.', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/asset/aircraft/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/asset/aircraft/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Asset creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, resetForm, getFieldProps, setFieldValue, handleSubmit, isSubmitting} = formik;

  

  const handleCancelClick = () => {
    resetForm();
    handleClose();
  };

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Add New Asset (Aircraft)</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              {/* Top section */}
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Type"
                  {...getFieldProps('craftType')}
                  onChange={(e) => setFieldValue('craftType', e.target.value)}
                >
                  {AC_TYPE.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  {...getFieldProps('year')}
                  fullWidth
                  type="number"
                  label="Year"
                  error={Boolean(touched.year && errors.year)}
                  helperText={touched.year && errors.year}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextFieldWithHover
                  {...getFieldProps('nickName')}
                  fullWidth
                  type="string"
                  label="Asset Name"
                  info="Input the asset name"
                />
                <TextField
                  {...getFieldProps('hullID')}
                  fullWidth
                  type="text"
                  label="HULL Identification Number"
                  inputProps={{style: {textTransform: 'uppercase'}}}
                  error={Boolean(touched.hullID && errors.hullID)}
                  helperText={touched.hullID && errors.hullID}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('maker')}
                  fullWidth
                  type="text"
                  label="Make"
                  error={Boolean(touched.maker && errors.maker)}
                  helperText={touched.maker && errors.maker}
                />
                <Autocomplete
                  fullWidth
                  options={AC_MODEL}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  freeSolo
                  onChange={(e, option) => setFieldValue('acModel', option.value)}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      label="Model"
                      variant="outlined"
                      value={values.acModel}
                      onChange={(e) => setFieldValue('acModel', e.target.value)}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Registration State"
                  {...getFieldProps('regState')}
                  onChange={(e) => setFieldValue('regState', e.target.value)}
                  error={Boolean(touched.regState && errors.regState)}
                  helperText={touched.regState && errors.regState}
                >
                  <MenuItem value="non-us">Non-US</MenuItem>
                  {US_STATES.map((option) => (
                    <MenuItem key={option.code} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                {values.regState === 'non-us' && (
                  <TextField
                    select
                    fullWidth
                    label="Please Specify Country"
                    value={values.regCountry}
                    onChange={(e) => setFieldValue('regCountry', e.target.value)}
                  >
                    {COUNTRY_LIST.map((option) =>
                      option.code !== 'US' ? (
                        <MenuItem key={option.code} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ) : null
                    )}
                  </TextField>
                )}
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('faaNumber')}
                  fullWidth
                  type="text"
                  label="FAA Number"
                  error={Boolean(touched.faaNumber && errors.faaNumber)}
                  helperText={touched.faaNumber && errors.faaNumber}
                />
                <ContactSelect
                  clientId={clientId}
                  keyValue="owner"
                  label="Owner"
                  values={values.owner}
                  setFieldValue={setFieldValue}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <ContactSelect
                  clientId={clientId}
                  keyValue="addInterest"
                  label="Additional Interest"
                  values={values.addInterest}
                  setFieldValue={setFieldValue}
                />
                <ContactSelect
                  clientId={clientId}
                  keyValue="pilots"
                  label="Pilots"
                  values={values.pilots}
                  setFieldValue={setFieldValue}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('nCrew')}
                  fullWidth
                  type="number"
                  label="Crew Seats"
                  error={Boolean(touched.nCrew && errors.nCrew)}
                  helperText={touched.nCrew && errors.nCrew}
                />
                <TextField
                  {...getFieldProps('nPassenger')}
                  fullWidth
                  type="number"
                  label="Passenger Seats"
                  error={Boolean(touched.nPassenger && errors.nPassenger)}
                  helperText={touched.nPassenger && errors.nPassenger}
                />
              </Stack>

              {/** Mid Section */}
              <Divider />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Stack sx={{p: 1}} spacing={1}>
                    <Typography variant="subtitle1">Registered Location</Typography>
                    <AddressFields
                      isRealEstate={false}
                      alignment="vertical"
                      addId={values.registeredLocationId}
                      handleChange={(id) => setFieldValue('registeredLocationId', id)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack sx={{p: 1}} spacing={1}>
                    <Typography variant="subtitle1">Hangar Location</Typography>
                    <TextField {...getFieldProps('iataNumber')} fullWidth type="text" label="IATA Location Number" />
                    <AddressFields
                      isRealEstate={false}
                      alignment="vertical"
                      addId={values.hangarLocationId}
                      handleChange={(id) => setFieldValue('hangarLocationId', id)}
                    />
                    <TextField
                      select
                      fullWidth
                      label="Storage Type"
                      {...getFieldProps('storeType')}
                      onChange={(e) => setFieldValue('storeType', e.target.value)}
                    >
                      {AC_STORE_TYPE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                </Grid>
              </Grid>
              {/** Footer */}
              <Divider />
              <Stack direction={{xs: 'column', md: 'row'}} spacing={1}>
                <NumberFormat
                  fullWidth
                  label="Market Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('marketValue')}
                  onChange={(e) => setFieldValue('marketValue', parseFloat(e.target.values.value))}
                />
                <DatePicker
                  fullWidth
                  label="Last Market Appraisal Date"
                  {...getFieldProps('lastMADate')}
                  onChange={(date) => setFieldValue('lastMADate', fDateZeroTime(date))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
                <NumberFormat
                  fullWidth
                  label="Last Market Appraisal Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('lastMAValue')}
                  onChange={(e) => setFieldValue('lastMAValue', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={1}>
                <NumberFormat
                  fullWidth
                  label="Replacement Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('rValue')}
                  onChange={(e) => setFieldValue('rValue', parseFloat(e.target.values.value))}
                />
                <DatePicker
                  fullWidth
                  label="Last Replacement Appraisal Date"
                  {...getFieldProps('lRADate')}
                  onChange={(date) => setFieldValue('lRADate', fDateZeroTime(date))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
                <NumberFormat
                  fullWidth
                  label="Last Replacement Appraisal Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('lRAValue')}
                  onChange={(e) => setFieldValue('lRAValue', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Asset Status"
                  {...getFieldProps('assetStatus')}
                  onChange={(e) => setFieldValue('assetStatus', e.target.value)}
                  helperText="Please select asset status."
                >
                  {ASSET_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <Box sx={{width: '100%'}}>
                  <FormControlLabel
                    control={<Switch {...getFieldProps('insured')} checked={values.insured} />}
                    label="Insured"
                    labelPlacement="start"
                  />
                </Box>
                {values.insured && (
                  <NumberFormat
                    fullWidth
                    label="Insured Value"
                    prefix="$"
                    placeholder="$0.00"
                    {...getFieldProps('insuredValue')}
                    onChange={(e) => setFieldValue('insuredValue', parseFloat(e.target.values.value))}
                  />
                )}
              </Stack>
              <Autocomplete
                fullWidth
                multiple
                loading={inLoading}
                options={policies}
                ListboxProps={{sx: {maxHeight: '160px'}}}
                disableCloseOnSelect
                getOptionLabel={(option) => `aircraft - ${option.policyNumber} - ${option.nickName}`}
                value={policies.filter((item) => values.rPolicies.some((pol) => pol.id === item.id))}
                onChange={(e, value) =>
                  setFieldValue(
                    'rPolicies',
                    value.map((item) => ({id: item.id, type: 'aircraft'}))
                  )
                }
                renderOption={(props, option, {selected}) => {
                  return (
                    <li {...props} key={option.id}>
                      <Checkbox checked={selected} />
                      Aircraft - {option.policyNumber} - {option.nickName}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Related Policies" />}
              />
              <Stack spacing={2}>
                <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
              </Stack>
              
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelClick} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}
